import { get, post } from "./config";
import { globalData } from "@/utils/global";
import Cookies from "js-cookie";

window.tiles3dLayer = [];
window.countyModel = [];
window.countyLayer = [];

window.allModels = [];
window.allLayers = [];
window.singleTileLayer = [];

// export const getTenant = (data) =>
//   get(`http://192.168.110.30:8080/api/blade-system/tenant/get-tenant`, data);
// export const getTenant = (data) => get(`http://192.168.110.58:8081/api/blade-system/tenant/get-tenant`, data);
export const getTenant = (data) =>
  get(`https://szxc.3dy.me/api/blade-system/tenant/get-tenant`, data);

getTenant({
  url: window.location.origin,
  iii: 565656,
}).then((res) => {
  url2 = res.data.url + "/api/";
  url1 = res.data.url + "/api/";
  url3 = res.data.url + "/api/";
  url4 = res.data.url + "/api/";
  tenantId = res.data.tenantId;

  Cookies.set("tenantId", tenantId, {
    expires: 7000,
  });
});

export const loginByCode = (data) =>
  post(
    `${url2}blade-auth/oauth/wx/callBack?code=${data.code}&url=${window.location.origin}&user_type=3`
  );

export const roleMenus = (data) => get(`${url2}router/role-menus`, data);

export const getWeather = (data) => get(`${url1}blade-desk/weather/data`, data);
export const getListSelect = (data) =>
  get(`${url2}bg/city/event/getListSelect`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取预警列表的图表数据

export const getListChart = (data) =>
  get(`${url2}bg/city/event/getListChart`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

//获取预警列表

export const getWarnListV2 = (data) =>
  get(`${url2}bg/city/event/getWarnListV2`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

//获取菜单统计数

export const getMenuCount = (data) =>
  get(`${url2}bg/city/event/getMenuCount`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

//获取摄像头统计信息
export const getMonitorCount = (data) =>
  get(`${url2}bg/city/event/getMonitorCount`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

//获取监控的预警列表
export const getMonitorEventV2 = (data) =>
  get(`${url2}bg/city/monitor/getMonitorEventV2`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
export const serviceTypeTree = (data) =>
  get(`${url2}ss/servicetype/serviceTypeTree`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取工程车管控的图表数据
export const getCarChart = (data) =>
  get(`${url2}bg/city/gcc/getChart`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
// 获取工程车通行列表
export const getCarList = (data) =>
  get(`${url2}bg/city/gcc/getList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取监控对应工程车列表
export const getGccMonitorList = (data) =>
  get(`${url2}bg/city/gcc/getGccMonitorList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取摄像头列表
export const getGccMonitorCount = (data) =>
  get(`${url2}bg/city/gcc/getGccMonitorCount`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
// 获取规划路径
export const getPlanLine = (data) =>
  get(
    `https://restapi.amap.com/v3/direction/driving?key=164b29a8a0abeedb05bdb9052916e2d8&origin=${data.origin}&destination=${data.destination}&waypoints=${data.waypoints}`
  );

// 根据车牌获取通行记录
export const getCarDetail = (data) =>
  get(`${url2}bg/city/gcc/getCarDetail`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 根据车牌获取通行记录
export const getCarPage = (data) =>
  get(`${url2}bg/city/gcc/getCarPage`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// ---------------------------分割线---------------------------------------------

// 项目筛选
export const filterProject = (data) =>
  get(`${url2}bg/linkPatrol/filterProject`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const eventFilterProject = (data) =>
  get(`${url2}bg/city/event/fileList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取网格
export const gridList = (data) =>
  get(`${url2}bg/linkPatrol/gridList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取懒加载网格
export const lazyGrid = (data) =>
  get(`${url2}bg/linkPatrol/lazyGrid`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 改造视频监控统计数
export const monitorCount = (data) =>
  get(`${url2}bg/linkPatrol/monitorCount`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 改造视频监控列表
export const monitorList = (data) =>
  get(`${url2}bg/linkPatrol/monitorList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 获取项目的摄像头
export const projectMonitor = (data) =>
  get(`${url2}bg/linkPatrol/projectMonitor`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 无人机事件详情
export const flightEventDetail = (data) =>
  get(`${url2}bg/linkPatrol/flightEventDetail`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
// 无人机日志列表
export const flightLogList = (data) =>
  get(`${url2}bg/linkPatrol/flightLogList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

// 无人机巡查列表
export const flightList = (data) =>
  get(`${url2}bg/linkPatrol/flightList`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const getLinkCheckTypes = (data) =>
  get(`${url2}blade-system/dict/dictionary`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const getAbsoluteEx = (data) =>
  post(`http://127.0.0.1:8888/api/hk/getAbsoluteEx`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
export const putAbsoluteEx = (data) =>
  post(`http://127.0.0.1:8888/api/hk/putAbsoluteEx`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
export const bindMonitor = (data) =>
  post(`${url2}hf/absoluteEx/bindMonitor`, data, {
    "content-type": "application/json;charset=UTF-8",
  });
export const getAnalysis = (data) =>
  get(`${url2}bg/city/chart/getAnalysis`, data);
export const eventPage = (data) => get(`${url2}/bg/city/event/page`, data);
export const eventExport = () => {
  return `${url2}bg/city/event/export`;
};

export const getSimpleGrid = (data) =>
  get(`${url2}bg/city/monitor/getSimpleGrid`, data);
export const getWarnList = (data) =>
  get(`${url2}bg/city/event/getWarnList`, data);
export const getSimpleList = (data) =>
  get(`${url2}bg/city/monitor/getSimpleList`, data);
export const getMonitorEvent = (data) =>
  get(`${url2}bg/city/monitor/getMonitorEvent`, data);
export const getEventTypeOther = (data) =>
  get(`${url2}bg/city/event/getEventType`, data);
// export const presetList = (data) => get(`${url2}bg/city/preset/list`, data);
export const presetList = (data) =>
  get(`${url1}ss/sspreset/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const delCollect = (data) =>
  post(`${url2}bg/city/monitor/delCollect/${data.id}`, data);
export const collect = (data) =>
  post(`${url2}bg/city/monitor/collect/${data.id}`, data);
export const getLastDetail = (data) =>
  get(`${url2}bg/city/monitor/getLastDetail`, data);
export const getEventDetail = (data) =>
  get(`${url2}bg/city/monitor/getEventDetail`, data);
export const monitorDetail = (data) =>
  get(`${url2}bg/city/monitor/monitorDetail`, data);
export const latestWarning = (data) =>
  get(`${url2}bg/city/monitor/latestWarning`, data);
export const getDeviceList = (data) =>
  get(`${url2}bg/city/monitor/getDeviceList`, data);
export const getChart = (data) => get(`${url2}bg/city/monitor/getChart`, data);
export const getSatusChart = (data) =>
  get(`${url2}bg/city/event/getChart`, data);
export const getMonitorType = (data) =>
  get(`${url2}bg/city/monitor/getMonitorType`, data);
export const getGridList = (data) =>
  get(`${url2}bg/city/monitor/getGridList`, data);
export const captcha = (data) => get(`${url2}blade-auth/oauth/captcha`, data);
export const login = (data) =>
  post(
    `${url2}blade-auth/oauth/bg/token?tenantId=${tenantId}&username=${data.username}&password=${data.password}&grant_type=captcha&scope=all&type=account&openId=${data.openId}&avatar=${data.avatar}&nickname=${data.nickname}`,
    {},
    {
      "Captcha-Code": data.code,
      "Captcha-Key": data.key,
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );
export const refreshToken = (data) =>
  post(
    `${url2}blade-auth/oauth/bg/token?tenantId=${tenantId}&refresh_token=${data.refresh_token}&grant_type=refresh_token`,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
      "Tenant-Id": `${tenantId}`,
    }
  );
export const wxUserDetails = (data) =>
  get(`${url2}wx/house/wxUserDetails`, data);

export const userGetIndex = (data) => get(`${url2}user/get-index`, data);
export const partyGetChart = (data) => get(`${url2}bg/party/get-chart`, data);

export const getsyrcCount = (data) => get(`${url2}bg/user/get-syrk-type`, data);
export const getCount = (data) => get(`${url2}bg/user/count`, data);
export const getMemberType = (data) => get(`${url2}bg/user/get-type`, data);
export const getMemberList = (data) => get(`${url2}bg/user/get-list`, data);
export const getMemberDetail = (data) => get(`${url2}bg/user/detail`, data);
// export const getMemberDetail = (data) => get(`${url2}type-detail`, data);

export const getMemberDetail1 = (data) =>
  get(`${url2}bg/user/type-detail`, data);
export const getRygkList = (data) => get(`${url2}bg/rygk/list`, data);
export const getDictionary = (data) =>
  get(`${url2}blade-system/dict-biz/dictionary`, data);
export const getRygkDetail = (data) => get(`${url2}bg/rygk/detail`, data);
export const getInoculate = (data) => get(`${url2}bg/user/get-inoculate`, data);
export const getVaccineList = (data) =>
  get(`${url2}bg/rygk/vaccine-list`, data);
export const getVaccineDetail = (data) =>
  get(`${url2}bg/user/inoculate-detail`, data);
export const dictionary = (data) =>
  get(`${url2}blade-system/dict-biz/dictionary`, data);
export const gridGetcount = (data) => get(`${url2}bg/party/get-count`, data);
export const gridGetcount1 = (data) => get(`${url2}bg/user/get-count`, data);

export const menus = (data) => get(`${url2}other-router/menus`, data);

export const EnterpriseGridlist = (data) => get(`${url3}bg/company/list`, data);
export const EnterpriseGridDetail = (data) =>
  get(`${url2}bg/company/detail`, data);
export const EnterpriseGridcount = (data) =>
  get(`${url3}bg/company/count`, data);
export const videoList = (data) => get(`${url2}news/video/list`, data);
export const introducegetindex = (data) =>
  get(`${url2}news/introduce/get-index`, data);

export const getTypeUser = (data) => get(`${url2}bg/user/get-type-user`, data);
export const wxpublicGrid = (data) =>
  get(`${url1}wx/grid/wxpublicGrid`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  永健

// 禁毒工作
// 禁毒工作动态列表
export const ssjddtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssjddtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 领导小组统计数量
export const leaderCount = (data) =>
  get(`${url1}wx/leader/leaderCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 领导小组及志愿者列表
export const leaderPage = (data) =>
  get(`${url1}wx/leader/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 禁毒制度接口
export const regimePage = (data) =>
  get(`${url1}wx/system/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const syHouseType = (data) =>
  get(`${url1}wx/house/syHouseType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const dpsyHouseList = (data) =>
  get(`${url1}wx/house/dpsyHouseList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const dateVo = (data) =>
  get(`${url1}wx/ssservicegovernance/dateVo`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const grSsServiceGovernanceList = (data) =>
  get(`${url1}wx/ssservicegovernance/grSsServiceGovernanceList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const personalEventPage = (data) =>
  get(`${url1}blade-desk/event/personalEventPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const houseTypeAll = (data) =>
  get(`${url1}blade-desk/housetype/houseTypeAll?tenantId=${tenantId}`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const dprkType = (data) =>
  get(`${url1}user/dprkType?tenantId=${tenantId}`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 深度网格-实有房屋分析
export const allhouseTypeStatistics = (data) =>
  get(`${url1}wx/house/allhouseTypeStatistics`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 深度网格-实有房屋排行
export const houseRanking = (data) =>
  get(`${url1}wx/house/houseRanking`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 深度网格-实有房屋统计
export const houseTypeStatistics = (data) =>
  get(`${url1}wx/house/houseTypeStatistics`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  深度网格-服务对象区域分析
export const fudxqyfxCount = (data) =>
  get(`${url1}user/fudxqyfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-人口年龄段分析
export const rknldCount = (data) =>
  get(`${url1}user/rknldCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-人口性别分析
export const sexCount = (data) =>
  get(`${url1}user/sexCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-实有人口统计接口
export const syrkCount = (data) =>
  get(`${url1}user/syrkCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-实有人口分析
export const syrkfxCount = (data) =>
  get(`${url1}user/syrkfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-实有人口排行
export const syrkphCount = (data) =>
  get(`${url1}user/syrkphCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  深度网格-重点服务对象
export const zdfwdxCount = (data) =>
  get(`${url1}user/zdfwdxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  数说疫情-人员管控
export const rygkCount = (data) =>
  get(`${url1}wx/house/rygk/rygkCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  数说疫情-疫苗接种
export const ymjzCount = (data) =>
  get(`${url1}user/ymjzCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  数说疫情-疫苗接种分析
export const ymjzfxCount = (data) =>
  get(`${url1}user/ymjzfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  数说综治-重点人群
export const zdrqCount = (data) =>
  get(`${url1}user/zdrqCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  数说综治-重点人员区域分析
export const zdryqyfxCount = (data) =>
  get(`${url1}user/zdryqyfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  松山大屏视频监控类型统计
export const monitoringTypeCount = (data) =>
  get(`${url1}blade-desk/monitoringequipment/monitoringTypeCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  数说养老-老年年龄分析
export const lnnlfxCount = (data) =>
  get(`${url1}user/lnnlfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  数说养老-老年人分布排行
export const lnrfbphCount = (data) =>
  get(`${url1}user/lnrfbphCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  数说养老-老年人性别分析
export const lnrxbCount = (data) =>
  get(`${url1}user/lnrxbCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  数说养老-养老网格
export const ylwgCount = (data) =>
  get(`${url1}user/ylwgCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 医养结合队伍统计
export const medicalCareCount = (data) =>
  get(`${url1}ss/ssmedicalcare/medicalCareCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 老人体育队伍人数分析
export const lrtyMedicalCare = (data) =>
  get(`${url1}ss/ssmedicalcare/lrtyMedicalCare`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏疫情网格统计
export const dpEpidemicGridCount = (data) =>
  get(`${url1}wx/ssepidemicgrid/dpEpidemicGridCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 网格配备人员分析
export const pbrySort = (data) =>
  get(`${url1}wx/ssepidemicgrid/pbrySort`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const ssEpidemicGridTree = (data) =>
  get(`${url1}wx/ssepidemicgrid/ssEpidemicGridTree`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏重要部件区域分析统计接口
export const qyfxCount = (data) =>
  get(`${url1}blade-desk/importantparts/qyfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏重要部件统计接口
export const zdbjCount = (data) =>
  get(`${url1}blade-desk/importantparts/zdbjCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏重要场所统计接口
export const zdcsCount = (data) =>
  get(`${url1}blade-desk/importantparts/zdcsCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏重要场所区域分析统计接口
export const csqyfxCount = (data) =>
  get(`${url1}blade-desk/importantparts/csqyfxCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const dpHousePage = (data) =>
  get(`${url1}wx/house/dpHousePage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const dpHouseholdPage = (data) =>
  get(`${url1}wx/house/dpHouseholdPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const findHouseHsrs = (data) =>
  get(`${url1}wx/house/findHouseHsrs`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const wxHouseDetails = (data) =>
  get(`${url1}wx/house/wxHouseDetails`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const szssHouseholdPage = (data) =>
  get(`${url1}wx/building/szssHouseholdPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const ssdpPage = (data) =>
  get(`${url1}blade-desk/isolationpoint/ssdpPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const ssdpPageDetail = (data) =>
  get(`${url1}blade-desk/isolationpoint/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const selectDpNucleicAcidPage = (data) =>
  get(`${url1}blade-desk/nucleicacid/selectDpNucleicAcidPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const nucleicacidDetail = (data) =>
  get(`${url1}blade-desk/nucleicacid/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const syfwHouse = (data) =>
  get(`${url1}wx/house/syfwHouse`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const getModelAddress = (data) =>
  get(`${url1}blade-desk/grid/getModelAddress`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const findBuilding = (data) =>
  get(`${url1}wx/building/findBuilding`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const largeScreenImportantPartsList = (data) =>
  get(`${url1}blade-desk/importantparts/ssImportantPartsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const selectLargeScreenKeyPlaces = (data) =>
  get(`${url1}blade-desk/importantparts/ssLargeScreenKeyPlaces`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const songshanteapost = (data) =>
  get(`${url1}blade-desk/news/songshanteapost`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const ssMonitoring = (data) =>
  get(`${url1}blade-desk/monitoringequipment/ssMonitoring`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 社区动态列表
export const ssSqdtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssSqdtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// export const ssSqdtNewsList = (data) => get(`http://192.168.110.40:8080/api/blade-desk/news/ssSqdtNewsList`, data, {
//   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// });
// 养老动态列表
export const ssYldtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssYldtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 综治动态列表
export const ssZzdtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssZzdtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// -----------------
//  医养结合详情接口
export const ssdetail = (data) =>
  get(`${url1}ss/ssmedicalcare/ssdetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  根据网格id统计医养结合总数
export const ssgetCount = (data) =>
  get(`${url1}/ss/ssmedicalcare/getCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//  大屏医养结合列表
export const dpSsMedicalCarePage = (data) =>
  get(`${url1}ss/ssmedicalcare/dpSsMedicalCarePage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  日照中心服务团队接口
export const rzzxfwServiceTeamPage = (data) =>
  get(`${url1}ss/ssserviceteam/rzzxfwServiceTeamPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  医养结合领导小组接口
export const yyjhldServiceTeamPage = (data) =>
  get(`${url1}ss/ssserviceteam/yyjhldServiceTeamPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  医养结合服务团队接口
export const yyjhfwServiceTeamPage = (data) =>
  get(`${url1}ss/ssserviceteam/yyjhfwServiceTeamPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//  大屏日间照料中心列表
export const dpSsCentralManagementPage = (data) =>
  get(`${url1}ss/sscentralmanagement/dpSsCentralManagementPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 视察调研列表
export const ssScdyNewsList = (data) =>
  get(`${url1}blade-desk/news/ssScdyNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 视察调研详情
export const ssScdydetail = (data) =>
  get(`${url1}blade-desk/news/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山党建动态列表
export const partybuildingList = (data) =>
  get(`${url1}blade-desk/news/partybuildingList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 疫苗接种折线统计图数据
export const ymjzzxtCount = (data) =>
  get(`${url1}vaccine/ymjzzxtCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 松山党建动态详情
export const partybuildingdetail = (data) =>
  get(`${url1}blade-desk/news/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

///工作日志-----------------------

// 治理、服务统计接口
export const governanceServiceStatistics = (data) =>
  get(`${url1}blade-desk/event/governanceServiceStatistics`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 时间折线图数据接口
export const selectDateData = (data) =>
  get(`${url1}blade-desk/event/selectDateData`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 事件类型统计接口
export const statisticsType = (data) =>
  get(`${url1}blade-desk/event/statisticsType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 事件大屏查询显示接口
export const eventLargeScreenDisplay = (data) =>
  get(`${url1}blade-desk/event/eventLargeScreenDisplay`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//服务治理-----------------------
// 服务治理处理状态统计
export const processingStatistics = (data) =>
  get(`${url1}wx/ssservicegovernance/processingStatistics`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 治理、服务统计接口
export const reportingStatistics = (data) =>
  get(`${url1}wx/ssservicegovernance/reportingStatistics`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 时间折线图数据接口
export const selectDate = (data) =>
  get(`${url1}wx/ssservicegovernance/selectDate`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 服务治理全部类型统计接口
export const reportStatisticsType = (data) =>
  get(`${url1}wx/ssservicegovernance/statisticsType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 服务治理大屏查询显示接口
export const largeScreenDisplay = (data) =>
  get(`${url1}wx/ssservicegovernance/largeScreenDisplay`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 服务治理详情接口
export const getDetails = (data) =>
  get(`${url1}wx/ssservicegovernance/getDetails`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//疫情动态列表
export const ssYqdtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssYqdtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//企业动态列表
export const ssQydtNewsList = (data) =>
  get(`${url1}blade-desk/news/ssQydtNewsList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 松山大屏随手拍列表
export const ssSlapCasuallyList = (data) =>
  get(`${url1}blade-desk/slapcasually/ssSlapCasuallyList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 松山大屏统计接口
export const ssdpCount = (data) =>
  get(`${url1}blade-desk/slapcasually/ssdpCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// -------------贤铭---------------

// /api/party/partybranch/bglist

export const bglist = (data) =>
  get(`${url3}party/partybranch/bglist`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const dynamicDetail = (data) =>
  get(`${url3}bg/company/dynamicDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const dynamicList = (data) =>
  get(`${url3}bg/company/dynamicList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//大屏红色阵地分页
export const redpositionList = (data) =>
  get(`${url3}big/redposition/list`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//大屏红色阵地动态详情
export const redDynamicDetail = (data) =>
  get(`${url3}big/redposition/dynamicDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//大屏红色阵地动态列表
export const redDynamicList = (data) =>
  get(`${url3}big/redposition/dynamicList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//大屏红色阵地详情
export const redredposition = (data) =>
  get(`${url3}big/redposition/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//大屏党建联盟详情
export const partyallianceDetail = (data) =>
  get(`${url3}big/partyalliance/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//大大屏党建联盟列表
export const partyallianceList = (data) =>
  get(`${url3}big/partyalliance/list`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 获取红色阵地类型字典
export const redDictionary = (data) =>
  get(`${url3}blade-system/dict-biz/dictionary`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 大屏网格巡查分页列表
export const gridpatrolList = (data) =>
  get(`${url3}bg/gridpatrol/list`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏主题列表
export const getBgthemes = (data) =>
  get(`${url3}themes/themes/bgthemes`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 保存主题设置
export const getBgsubmit = (data) =>
  post(`${url3}themes/usertheme/bgsubmit`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// // 大屏主题列表
// export const getBgthemes = (data) => get(`http://192.168.110.45:8080/api/themes/themes/bgthemes`, data, {
//   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// });

// // 保存主题设置
// export const getBgsubmit = (data) => post(`http://192.168.110.45:8080/api/themes/usertheme/bgsubmit`, data, {
//   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// });

//  ---------       维佳----------------------
export const vrPage = (data) =>
  get(`${url4}blade-desk/vr/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 视频列表
export const videolinkage = (data) =>
  get(`${url4}tea/videolinkage/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const gridpatrolDetail = (data) =>
  get(`${url4}bg/gridpatrol/detail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const governancedetail = (data) =>
  get(`${url4}bg/gridpatrol/governancedetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
//关联事件选项接口
export const getEventType = (data) =>
  get(`${url4}tea/videolinkage/getEventType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//网格联动人员选项接口
export const getGridLink = (data) =>
  get(`${url4}tea/videolinkage/getGridLink`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 整合网格巡检和视频联动列表接口
export const unionPage = (data) =>
  get(`${url3}bg/gridpatrol/unionPage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 获取联动次数和网格巡查数
export const statisticalCount = (data) =>
  get(`${url3}bg/gridpatrol/statisticalCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 创建视频联动操作

export const createVideoLinkage = (data) =>
  post(`${url4}tea/videolinkage/createVideoLinkage`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 视频联动详情接口
export const getVideoInfoDetail = (data) =>
  get(`${url4}tea/videolinkage/getVideoInfoDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 开启混流
export const startMixedFlow = (data) =>
  post(`${url4}tea/videolinkage/startMixedFlow`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 更新联动人员定位接口
export const updatePositon = (data) =>
  post(`${url4}tea/videolinkagelocation/save`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 结束混流
export const endMixedFlow = (data) =>
  post(`${url4}tea/videolinkage/endMixedFlow`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 结束视频联动接口
export const getVideoLinkageOver = (data) =>
  post(`${url4}tea/videolinkage/getVideoLinkageOver`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 删除房间
export const deleteVideoLinkageId = (data) =>
  get(`${url4}tea/videolinkage/deleteVideoLinkageId`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 获取用户关联的视频联动表数据
export const getVideoLinkageId = (data) =>
  get(`${url4}tea/videolinkage/getVideoLinkageId`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getVideoInfoDetailEventId = (data) =>
  get(`${url4}tea/videolinkage/getVideoInfoDetailEventId`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 联动列表接口
export const videolinkagePage = (data) =>
  get(`${url4}tea/videolinkage/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 摄像头列表接口
export const getMonitoringEquipment = (data) =>
  get(`${url4}blade-desk/monitoringequipment/getMonitoringEquipment`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 简介列表接口
export const introduceIndex = (data) =>
  get(`${url4}party/introduce/get-index`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏 旧城改造模型地址
export const getAllModelList = (data) =>
  get(`${url4}blade-desk/oldcitymodel/getAllModelList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 大屏 改造项目类型
export const selectDpType = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 大屏 改造项目类
export const selectDpSortType = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpSortType`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏 改造项目列表详情
export const selectDpDetail = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏 改造项目列表
export const selectDpList = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏 旧城统计
export const selectDpStatistical = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpStatistical`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏改造项目区域分析
export const selectDpRegionTransform = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpRegionTransform`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 大屏 旧城规划大屏详情接口
export const selectDpPlanDetail = (data) =>
  get(`${url4}blade-desk/oldcityplan/selectDpPlanDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 旧城规划大屏接口
export const selectDpPlanList = (data) =>
  get(`${url4}blade-desk/oldcityplan/selectDpPlanList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 旧城规划领导小组
export const getDpLeadingGroup = (data) =>
  get(`${url4}blade-desk/oldcityleadinggroup/getDpLeadingGroup`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 规划文件
export const selectDpFileList = (data) =>
  get(`${url4}blade-desk/oldimplementunit/selectDpFileList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 实施单位列表接口
export const selectDpUnitList = (data) =>
  get(`${url4}blade-desk/oldimplementunit/selectDpUnitList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 改造规划Vr接口
export const selectOldCityDpVr = (data) =>
  get(`${url4}blade-desk/oldcityvr/selectOldCityDpVr`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 改造项目规划文件
export const selectDpFileTransform = (data) =>
  get(`${url4}blade-desk/oldcitytransform/selectDpFileTransform`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// ------------------------------------------------------------------------------------------------

export const getvideos = (data) =>
  post(`https://time.3dy.me/api/album-record/videos`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const getvideosFliter = (data) =>
  post(`https://time.3dy.me/api/album-record/v2/videos`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const removeVideo = (data) =>
  post(`https://time.3dy.me/api/album-record/removeVideo`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const getVideoSize = (data) =>
  get(`https://time.3dy.me/api/album-record/getVideoSize`, data, {
    "content-type": "application/json;charset=UTF-8",
  });

export const getjson = (data) => get(`http://127.0.0.1/my_config.json`, data);

// ------------ 海上  振坤--------
// let uuu = 'http://192.168.110.47:8080/api/'

export const getCountData = (data) =>
  get(`${url1}transformLog/getCountData`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const getData = (data) =>
  get(`${url1}transformLog/getData`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getBizData = (data) =>
  get(`${url1}transformLog/getBizData`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const bgList = (data) =>
  get(`${url1}transformLog/bgList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const bgDetail = (data) =>
  get(`${url1}transformLog/bgDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 长春镇大屏监控列表(根据租户获取监控数据)
export const getMonitoring = (data) =>
  get(`${url1}blade-desk/monitoringequipment/getMonitoring`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 长春镇大屏监控数量(根据租户获取监控数量)
export const getMonitoringCount = (data) =>
  get(`${url1}blade-desk/monitoringequipment/getMonitoringCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getByCode = (data) =>
  get(`${url1}blade-system/dict-biz/getByCode`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getTbUrl = (data) =>
  get(`${url1}blade-system/dict-biz/getByCode?code=tbdz`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const treeBg = (data) =>
  get(`${url1}landmaptype/landmaptype/treeBg`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getMapViews = (data) =>
  get(`${url1}blade-desk/grid/getMapViews`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

let uu = "http://192.168.110.44:8080/api/";
// -----------贤明------------
// 获取养殖图斑详情
export const getBreedDetail = (data) =>
  get(`${url1}hf/hsyzdetail/getBreedDetail`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// export const getBreedDetail = (data) => get(`${url1}hf/absoluteEx/detail`, data, {
//   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
// });

// 绑定监控视角
export const bindView = (data) =>
  post(`${url1}hf/hsyzdetail/bindView`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 海上养殖vr列表
export const yzlist = (data) =>
  get(`${url1}blade-desk/vr/yzlist`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const countPreset = (data) =>
  get(`${url1}hf/hsyzdetail/countPreset`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 绑定摄像头视角
export const submitView = (data) =>
  post(`${url1}blade-desk/monitoringequipment/submit`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getTbData = (data) =>
  get(
    `https://live.3dy.me/SEA/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=SEA:${data.type}&outputFormat=json&filter=${data.filter}`
  );
export const getAllTbData = (data) =>
  get(
    `https://live.3dy.me/SEA/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=SEA:${data.type}&outputFormat=json`
  );

//获取筛选项
export const getSeaSelect = (data) =>
  get(`${url1}hf/hsyzdetail/getSelect`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//获取统计数和网格名称
export const getPageCount = (data) =>
  get(`${url1}hf/hsyzdetail/getPageCount`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

//获取列表
export const getSeaPage = (data) =>
  get(`${url1}hf/hsyzdetail/bg/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// ------------视频---------------

export const getVideoAddress = (data) =>
  post(`${url1}bg/monitor/getLargeScreenMonitorAddress`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const getMonitor = (data) =>
  get(`${url1}bg/monitor/getAccessToken`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  }); //  获取实时token
export const ptzStart = (data) =>
  post(`https://open.ys7.com/api/lapp/device/ptz/start`, data); // 开启云台
export const ptzStop = (data) =>
  post(`https://open.ys7.com/api/lapp/device/ptz/stop`, data); // 停止云台
export const presetMove = (data) =>
  post(`https://open.ys7.com/api/lapp/device/preset/move`, data); // 调用预置点
export const presetAdd = (data) =>
  post("https://open.ys7.com/api/lapp/device/preset/add", data); // 添加预置点
export const presetClear = (data) =>
  post("https://open.ys7.com/api/lapp/device/preset/clear", data); // 清除
export const addressGet = (data) =>
  post("https://open.ys7.com/api/lapp/v2/live/address/get", data); // 获取播放地址
export const capture = (data) =>
  post("https://open.ys7.com/api/lapp/device/capture", data); // 设备抓拍图片
export const getLargeScreenMonitorAddress = (data) =>
  post(`${url1}bg/monitor/getLargeScreenMonitorAddress`, data);
// 预置点添加
export const createSeaPtz = (data) =>
  post(`${url1}preset/save`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 预置点列表
export const createSeaPage = (data) =>
  get(`${url1}/ss/sspreset/page`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// 预置点删除
export const deleteSeaPtz = (data) =>
  get(`${url1}preset/delete`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
// ---------------------------------------------新接口---------------------------------------------------

export const getBGMenu = (data) =>
  get(`${url1}router/getBGMenu`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getIntroduce = (data) =>
  get(`${url1}bg/introduce/getIntroduce`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

// 此刻东冲
export const getSelect = (data) =>
  get(`${url1}bg/monitor/getSelect`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getScenicSelect = (data) =>
  get(`${url1}bg/scenic/getSelect`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getList = (data) =>
  get(`${url1}bg/monitor/getList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getScenicList = (data) =>
  get(`${url1}bg/scenic/getScenicList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });

export const getIntroduceList = (data) =>
  get(
    `${url1}bg/introduce/getIntroduceList
`,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );

export const getDefaultViews = (data) =>
  get(
    `${url1}blade-desk/grid/getDefaultViews
`,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );

export const getUserByTbCode = (data) =>
  get(
    `${url1}hf/hsyzdetail/getUserByTbCode
`,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );

// -----------最新接口---------------------------------
export const getDpImportantParts = (data) =>
  get(
    `${url1}user/getDpImportantParts
  `,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );

export const getSimpleListOther = (data) =>
  get(`${url1}wechat/city/event/getSimpleList`, data, {
    Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
  });
export const getRCsbList = (data) =>
  get(
    `${url1}bg/city/ISC/getRCsbList
    `,
    data,
    {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }
  );
