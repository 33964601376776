<template>
  <el-drawer
    v-model="show"
    :with-header="false"
    direction="btt"
    :append-to-body="true"
    size="100%"
  >
    <svg
      @click="clearYSY()"
      :style="!cameraType ? 'right:20px;' : ''"
      class="box_camera_clear"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
      ></path>
    </svg>
    <div class="allContent divFlex alignCenter flexCenter">
      <el-scrollbar class="grow" v-if="cameraType">
        <div class="allVideoList column" v-if="allList && allList.length > 0">
          <div
            class="videoItem"
            :style="
              nowChooseCameraId == item.id
                ? 'border:1px solid white;border-radius: 5px;'
                : ''
            "
            @click="clickItem(item)"
            v-for="(item, idx) in allList"
            :key="idx + 'dfgdf'"
          >
            <div class="videoAvatar">
              <img class="theAvatar" :src="item.cover" alt="" />
              <img src="@/assets/sea/images/ai.png" class="playBtn" alt="" />
            </div>
            <div class="videoAddress">{{ item.monitorName || "--" }}</div>
          </div>
        </div>
        <el-empty
          v-else
          :image-size="91"
          description="暂无数据"
          :image="require('../assets/images/null.png')"
        />
      </el-scrollbar>
      <div class="box_camera" v-if="cameraType">
        <div class="box_camera_content">
          <!-- <div id="videContainer"></div> -->
          <Hk
            v-if="livePlayUrl && switchVideoShow"
            idStr="videContainer"
            :camera="camera"
            :allUrls="livePlayUrl"
            splitNum="1"
            :mode="1"
            :chooseBtn="chooseBtn"
            :needCapture="true"
          />

          <Viewcamera :token="token" :cameraInfo="camera" />

          <div class="subLinkBox">
            <div class="timeBox">
              <span>云台联动视角</span>
            </div>

            <img
              class="viewImg"
              v-if="info.ptz || viewsObj"
              :src="viewsObj.image || info.image"
              alt=""
            />
            <div class="viewImg1 divFlex flexCenter alignCenter" v-else>
              <div class="addLink column flexCenter alignCenter">
                <img src="@/assets/sea/images/link.png" alt="" />
                <span class="addLinkText">暂无</span>
              </div>
            </div>
            <!-- <img class="viewImg" src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" alt=""> -->
            <div class="div30"></div>
            <div class="timeBox">
              <span>云台联动图斑信息</span>
            </div>
            <div class="detailInfoItem">
              <div>图斑编号：</div>
              <div>{{ info.图斑编号 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>面积：</div>
              <div>{{ info.养殖面积 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>养殖类型：</div>
              <div>{{ info.养殖类型 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>养殖品种：</div>
              <div>{{ info.养殖品种 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>养殖数量：</div>
              <div>{{ info.养殖数量 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>归属网格：</div>
              <div>{{ info.区县 || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>X质心坐标：</div>
              <div>{{ info["X质心坐标"] || "--" }}</div>
            </div>
            <div class="detailInfoItem">
              <div>村名：</div>
              <div>{{ info["村"] || "--" }}</div>
            </div>
            <div class="div30"></div>
            <div class="subBtn" @click="subLink()">确定关联</div>
          </div>
        </div>
        <div class="box_camera_datas">
          <div class="box_camera_name">
            <Icons v-if="camera.type == '1'" name="a-jiankong1" class="icon3" />
            <Icons v-if="camera.type == '2'" name="a-jiankong3" class="icon3" />
            {{ camera.type == "1" ? "AI智能感知摄像机" : "高位球机" }}
          </div>
          <div class="box_camera_data">
            名称：<span>{{ camera.equipmentName }}</span>
          </div>
          <div class="box_camera_data">
            型号：<span>{{ camera.serialNumber }}</span>
          </div>
          <div class="box_camera_data">
            位置：<span>{{ camera.address }}</span>
          </div>
          <!-- <div class="box_camera_data">
            监控方向：<span>{{ camera.direction }}</span>
          </div> -->
          <div class="box_camera_data">
            是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import EZUIKit from "ezuikit-js";
import { Viewcamera } from "@/components";
import SeaTilte from "./SeaTilte.vue";

import PresuppositionView from "./PresuppositionView.vue";
import Hk from "./Hk.vue";
import http from "@/api/server.js";
import {
  getAbsoluteEx,
  bindMonitor,
  putAbsoluteEx,
  getMonitor,
  addressGet,
  getVideoAddress,
  createSeaPage,
  bindView,
  capture,
  presetAdd,
  getMonitoring,
  // --------------
  getSimpleListOther,
} from "@/api";
import Icons from "./Icons.vue";
var player = null;
export default {
  name: "Ysy",
  components: { Viewcamera, PresuppositionView, Icons, SeaTilte, Hk },
  props: ["camera", "info"],
  watch: {},
  inject: ["globalData"],
  data() {
    return {
      chooseBtn:true,
      show: true,
      isHui: true,
      token: false,
      cameraType: false,
      viewArr: [],
      viewsObj: false, // 当前设置的云台视角
      // ------------云闪播--
      shanboUrl: false,
      shanboShow: false,
      datetime: "",
      allList: false,
      nowChooseCameraId: false,
      livePlayUrl: "",
      switchVideoShow:false
    };
  },

  async mounted() {
    await this.getList();

    if (!this.camera) return;
    console.log(this.camera)
    this.cameraType = this.camera.type != 3;
    // console.log(this.cameraType)
    if (this.camera === true) return;

    this.getAllViews();

    if (Object.keys(this.camera).length === 0) {
      this.initUrl(this.allList[0]);
    } else {
      this.initUrl(this.camera);
    }
  },
  setup(props, ctx) {
    const clearClick = (data) => {
      ctx.emit("clearClick", data);
    };
    return { clearClick };
  },
  methods: {
    initUrl(camera) {
      this.switchVideoShow = false
      http
        .iscBatchPlayAddressNew({
          cameraIndexCodes: camera.deviceNumber,
          protocol: "ws",
          streamType: 0,
          url: this.globalData.IP,
        })
        .then((res) => {
          this.livePlayUrl = res.data.map((e) => {
            return e.url;
          });
          this.switchVideoShow = true
        });
    },
    async getAllViews() {
      await createSeaPage({
        id: this.camera.monitorId || this.camera.id,
        monitorId: this.camera.id,
      }).then((res) => {
        this.viewArr = res.data;
      });
    },
    clickItem(item) {
      // console.log(item)
      if (item.id == this.nowChooseCameraId) return;
      this.$emit("serTbCamera", item);
      this.nowChooseCameraId =
        this.nowChooseCameraId == item.id ? false : item.id;
      this.initUrl(item);
    },
    async getList() {
      await getSimpleListOther().then((res) => {
        this.allList = res.data;
      });
    },
    async clearYSY(fun = false) {
      // if(!player) return
      this.$emit("updateTbData");
      if (player) {
        await player.stop().then(async () => {});
      }
      // player.destroy().then(() => {
      if (fun === false) {
        this.show = false;
        setTimeout(() => {
          this.clearClick();
        }, 200);
      } else {
        fun();
      }
      // });
    },

    // 设置预设点
    clickSetPreset() {
      var images = this.globalData.imageData.split(
        "data:image/jpeg;base64,"
      )[1];
      return new Promise((resolve, reject) => {
        http
          .createSeaPtz({
            cameraIndexCode:
              this.camera.serialNumber || this.camera.deviceNumber,
            monitorId: this.camera.monitorId || this.camera.id,
            images,
            number:this.viewArr.length + 1,
            name:''
          })
          .then((res) => {
            resolve(this.viewArr.length + 1);
          });
      });
    },
    // 新增预设点
    async clickDeviceCapturePic() {
      // console.log(this.info)
      await this.getAllViews();
      var code = this.viewArr.length > 0 ? this.viewArr.length + 1 : 1;
      if (code > 255) return this.msg("error", "最多添加255个预设视角！");

      let obj = {
        code: this.info.图斑编号,
        tbCode: this.info.图斑编号,
        image: this.globalData.imageData,
        monitorId: this.nowChooseCameraId
          ? this.nowChooseCameraId
          : this.camera.id,
        number: false,
      };

      await this.clickSetPreset().then((ress) => {
        obj.number = ress;
      });
      return obj;
    },
    async subLink() {
      if (!this.nowChooseCameraId && (!this.camera || !this.camera.id)) {
        return this.$message({
          message: "该图斑未绑定设备，请选择一个设备！！",
          type: "warning",
        });
      }
      let obj = await this.clickDeviceCapturePic();
      this.viewsObj = obj;

      bindView(obj).then((res) => {
        if (res.code == 200) {
          this.$emit("updateTbData");
          return this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          return this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/default.scss";

.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.allContent {
  width: 100%;
  height: 980px;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  background: rgba(0, 0, 0, 0.8);
}

.liveVideo {
  width: 1520px;
  height: 960px;
  background: rgba(7, 28, 49, 1);
  border-radius: 20px;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 10px;
  position: relative;

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: 20px;
    object-fit: contain;
  }
}

.box_camera {
  width: 1520px;
  height: 960px;
  background: #000;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: all 0.3s;
  // position: fixed;
  // left: 50%;
  // top: 80px;
  box-sizing: border-box;
  padding: 10px;
  // transform: translate(-50%, 0px);

  .box_camera_content {
    width: 100%;
    height: 882px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;

    .subLinkBox {
      width: 240px;
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid #000000;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 99;

      .timeBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        background: rgba(38, 68, 142, 0.3);
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(69, 205, 245, 1);
        line-height: 20px;
        margin-bottom: 15px;

        span {
          margin-left: 10px;
        }
      }

      .timeBox::before {
        content: "";
        display: block;
        position: absolute;
        width: 5px;
        height: 20px;
        background: var(--servicesPin3);
        border-radius: 0px 4px 4px 0px;
      }

      .viewImg {
        width: 100%;
        height: 115px;
        border-radius: 5px;
        object-fit: cover;
      }

      .viewImg1 {
        width: 100%;
        height: 115px;
        border-radius: 5px;
        object-fit: cover;
        background: rgba(255, 255, 255, 0.125);
        border: 1px solid rgba(255, 255, 255, 0.25);

        .addLink {
          img {
            width: 36px;
            object-fit: contain;
            opacity: 0.8;
          }

          .addLinkText {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(128, 137, 163, 0.7);
            margin-top: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }
}

.subBtn {
  width: 120px;
  height: 24px;
  background: rgba(69, 205, 245, 0.5);
  border: 1px solid #45cdf5;
  border-radius: 12px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #befff6;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.detailInfoItem {
  width: 100%;
  height: 24px;
  display: flex;

  > div:nth-child(1) {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(121, 132, 159, 1);
    line-height: 24px;
    box-sizing: border-box;
    text-align: left;
  }

  > div:nth-child(2) {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #e9f0fe;
    line-height: 24px;
  }
}

.rox_camera {
  width: 100%;
  height: 640px;
  transition: all 0.3s;
  background: rgba(7, 28, 49, 1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  // position: fixed;
  // left: 0;
  // bottom: 200px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 520px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 0px auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #a6ffef;
    background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 18px;
    font-weight: 400;
    color: rgba(91, 119, 147, 1);
    line-height: 26px;

    span {
      color: rgba(193, 222, 224, 1);
    }
  }
}

.box_camera_clear {
  position: fixed;
  right: 30px;
  top: 85px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.box_camera_button {
  width: 120px;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(34, 226, 255, 1) 0%,
    rgba(166, 255, 239, 1) 100%
  );
  // box-shadow: 0px 5px 15px 0px rgba(1, 180, 255, 0.25),
  //   0px 10px 50px 0px rgba(1, 180, 255, 0.25);
  border-radius: 20px;
  margin-bottom: -5px;
  -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #071c31;

  cursor: pointer;
}

#videContainer {
  width: 100%;
  height: 100%;
}

// -----------------------列表css
.allVideoList {
  width: 380px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;

  .videoItem {
    margin-bottom: 25px;

    .videoAvatar {
      width: 100%;
      height: 190px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      background: rgba(32, 32, 32, 1);

      .theAvatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .playBtn {
        width: 70px;
        border-radius: 50%;
        object-fit: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .addLink {
        img {
          width: 36px;
          object-fit: contain;
          opacity: 0.8;
        }

        .addLinkText {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(128, 137, 163, 0.7);
          margin-top: 10px;
          line-height: 14px;
        }
      }
    }

    .videoAddress {
      background: rgba(38, 68, 142, 0.3);
      width: fit-content;
      height: fit-content;
      position: relative;
      margin-top: 10px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b8d5d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .videoAddress::before {
      content: "";
      width: 8px;
      height: 14px;
      background-color: rgba(69, 205, 245, 1);
      border-radius: 0 50px 50px 0;
      margin-right: 4px;
    }

    .statusBtn {
      width: 40px;
      height: 18px;
      background: rgba(77, 83, 102, 0.75);
      border-radius: 9px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ff7521;
      line-height: 18px;
      text-align: center;
    }

    .statusOutBtn {
      width: 40px;
      height: 18px;
      background: rgba(77, 83, 102, 0.75);
      border-radius: 9px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(128, 154, 163, 1);
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
