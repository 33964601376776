<template>
  <div class="content">
    <div id="mars3dContainer"></div>
    <!-- <div @click="test111" class="test111">test111</div> -->
    <div class="tuichu">
      <div
        class="manageBtn divFlex alignCenter flexBet notCheckChildren mousePoint"
        style="margin-right: 10px"
        v-show="path == '/FishingRaft'"
      >
        <el-checkbox v-model="globalData.manageShowInMap" size="large" />
        <span @click.stop="showManage()" class="radioName">图层管理</span>
        <el-icon
          v-if="!globalData.showManageList"
          @click.stop="showManage()"
          class="manageIcon"
          ><CaretTop
        /></el-icon>
        <el-icon v-else @click.stop="showManage()" class="manageIcon"
          ><CaretBottom
        /></el-icon>
      </div>

      <img @click="reflach()" :src="require('@/assets/dy/sx.png')" />
      <img @click="clear" :src="require('@/assets/dy/tuichu.png')" />
      <img @click="windowClose()" :src="require('@/assets/dy/gbgbgb.png')" />
    </div>

    <!-- <div
      class="comBoxPin1"
      style="display: none"
      :id="'scenicRef' + item.id"
      v-for="(item, idx) in scenicList"
      :key="item + idx"
    >
      <div class="comContent">
        <img src="@/assets/img/b13.png" alt="" />
        <div class="nameBox">{{ item.title }}</div>
        <img src="@/assets/img/b122.png" alt="" />
        <lottie
          style="width: auto; height: auto"
          :options="{
            animationData: dataJson1,
            loop: true,
          }"
        />
      </div>
    </div> -->
    <!-- 新事件提醒框 -->
    <div class="newReportNews" v-if="initShow && bbData">
      <div class="newText">
        {{
          bbData.monitorName +
          " 【" +
          bbData.licensePlate +
          "】 " +
          bbData.azimuth
        }}
      </div>
    </div>

    <transition name="diy-slide-right">
      <Manages
        :class="globalData.showLr ? '' : 'right_More'"
        v-if="initShow && globalData.projectId !== false"
        v-show="globalData.showManageList"
        :name="keyword"
      />
    </transition>

    <transition name="el-fade-in-linear">
      <Annotation
        v-if="initShow && globalData.bzShow"
        :info="globalData.bzData"
      ></Annotation>
    </transition>

    <!-- 背景 -->
    <Background />

    <!-- 详情弹框 -->
    <transition name="el-fade-in-linear">
      <DialogCnt :info="detailshow" v-if="detailshow" />
    </transition>
    <!-- 模块 -->
    <!-- v-if="globalData.menuArr && globalData.menuArr.length > 0" -->
    <transition name="el-fade-in-linear">
      <keep-alive>
        <Model
          v-if="initShow && globalData.menuArr && globalData.menuArr.length > 0"
        />
      </keep-alive>
    </transition>

    <BottomNav :secMenu="secMenu" v-if="secMenu" />
    <!-- ---------------------------------------------------- -->
    <transition name="el-fade-in-linear">
      <keep-alive>
        <AllNav
          @setSecMenu="setSecMenu"
          v-if="initShow"
          v-show="globalData.allNavShow"
        />
      </keep-alive>
    </transition>

    <transition name="el-fade-in-linear">
      <keep-alive>
        <LeftMenu
          v-if="
            globalData.menuArr &&
            globalData.menuArr.length > 0 &&
            routeArr.includes(path)
          "
        />
      </keep-alive>
    </transition>

    <!-- ------------------------------- -->

    <!-- 按钮 -->
    <Groupbutton
      v-if="path != '/Overview'"
      @onClickArea="onClickArea"
      @onClickDistance="onClickDistance"
      @onClickPoint="onClickPoint"
    />
    <!-- vr -->
    <transition name="el-fade-in-linear">
      <IframePart
        v-if="$route.path == '/Overview' && vrUrl"
        :url="vrUrl"
        :zIndex="4"
      />
    </transition>

    <!-- 头部天气日期 -->

    <transition name="el-fade-in-linear">
      <keep-alive>
        <Headers1 v-if="initShow" />
      </keep-alive>
    </transition>
    <!-- 大图预览 -->
    <el-image-viewer
      v-if="viewer && viewer.list"
      :url-list="viewer.list"
      @close="close()"
      @switch="toSwitch"
      infinite
      :initial-index="viewer.key"
    ></el-image-viewer>

    <el-dialog title="模型地址设置" v-model="urlsetShow" width="30%" center>
      <el-form label-position="right" style="width: 100%">
        <el-form-item label="地址" prop="title">
          <el-input
            v-model="modelUrl"
            placeholder="请输入模型地址,多个以,分割,注意是英文逗号"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="urlsetShow = false">取 消</el-button>
        <el-button type="primary" @click="subUrl()">确 定</el-button>
        <el-button type="primary" @click="reUrl()">复 位</el-button>
      </span>
    </el-dialog>

    <el-dialog title="本地模型配置" v-model="MemoryShow" width="50%" center>
      <el-form
        :model="modelOption"
        label-position="right"
        style="width: 100%"
        label-width="150px"
      >
        <el-form-item label="显存值" prop="title">
          <el-input
            :controls="false"
            type="number"
            v-model="modelOption.Memory"
            placeholder="请输入显存数值(默认值：216)"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="额定显存大小" prop="title">
          <el-input
            type="number"
            :controls="false"
            v-model="modelOption.cacheBytes"
            placeholder="请输入额定显存大小(默认值：536870912)"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="最大显存大小" prop="title">
          <el-input
            :controls="false"
            type="number"
            v-model="modelOption.maximumCacheOverflowBytes"
            placeholder="最大显存大小(以字节为单位，默认值：536870912)"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="最大屏幕空间错误" prop="title">
          <el-input
            :controls="false"
            type="number"
            v-model="modelOption.maximumScreenSpaceError"
            placeholder="数值加大，能让最终成像变模糊(默认值：16)"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="倍增器" prop="title">
          <el-input
            :controls="false"
            type="number"
            v-model="modelOption.cullRequestsWhileMovingMultiplier"
            placeholder="在移动时选择请求时使用的倍增器。越大的选择性越强(默认值：60)"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="" prop="title">
          <el-checkbox v-model="modelOption.preloadFlightDestinations"
            >飞行目的地预加载贴图</el-checkbox
          >
          <el-checkbox v-model="modelOption.skipLevelOfDetail"
            >是否应应用跳过详细信息的级别</el-checkbox
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="MemoryShow = false">取 消</el-button>
        <el-button type="primary" @click="subMemory()">确 定</el-button>
        <el-button type="primary" @click="reMemory()">复 位</el-button>
      </div>
    </el-dialog>
    <audio
      ref="allMp"
      class="allMp"
      id="allMp"
      controls
      :src="require(`@/assets/audio/车辆.mp3`)"
    ></audio>
  </div>
</template>

<script>
import lottie from "vue-lottie";
import {
  Model,
  Headers,
  Background,
  Headers1,
  LeftMenu,
  Groupbutton,
  Modules,
  AllNav,
  BottomNav,
} from "./components";
import { IframePart, speechVoice } from "@/componentss";
import { Manages, DialogCnt, Annotation } from "@/components";
import {
  refreshToken,
  getBGMenu,
  getModelAddress,
  getDefaultViews,
  wxpublicGrid,
} from "@/api";
import { Throttle, Debounce } from "@/utils";
import dataJson from "@/assets/jsons/01标题.json";
import dataJson1 from "@/assets/jsons/05线.json";
import dataJson2 from "@/assets/jsons/03播放.json";
import dataJson3 from "@/assets/jsons/03暂停.json";
let graphicLayer; // 矢量图层对象
export let socket;
let rotatePoint;
let measure; // 矢量图层对象
export default {
  name: "Layout",
  inject: ["globalData"],
  components: {
    Annotation,
    BottomNav,
    AllNav,
    Model,
    Headers,
    Background,
    Headers1,
    LeftMenu,
    DialogCnt,
    IframePart,
    lottie,
    speechVoice,
    Groupbutton,
    Modules,
    Manages,
  },
  data() {
    return {
      oldRight: false,
      bbData: false,
      keyword: "",
      routeArr: [
        "/Overview",
        "/VillageDivision",
        "/NowScenic",
        "/PersonagesRecords",
        "/GrassrootsOrganizations",
        "/Education",
        "/LifeStyle",
        "/ChronicleEvent",
      ],
      baseOption: {
        Memory: 216,
        // 额定显存大小(以字节为单位)，允许在这个值上下波动
        cacheBytes: 536870912,
        // 最大显存大小(以字节为单位)。
        maximumCacheOverflowBytes: 536870912,
        // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
        maximumScreenSpaceError: 16,
        // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
        cullRequestsWhileMovingMultiplier: 60,
        // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
        preloadFlightDestinations: true,
        // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
        skipLevelOfDetail: false,
      },
      modelOption: {
        Memory: 216,
        // 额定显存大小(以字节为单位)，允许在这个值上下波动
        cacheBytes: 536870912,
        // 最大显存大小(以字节为单位)。
        maximumCacheOverflowBytes: 536870912,
        // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
        maximumScreenSpaceError: 16,
        // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
        cullRequestsWhileMovingMultiplier: 60,
        // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
        preloadFlightDestinations: true,
        // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
        skipLevelOfDetail: false,
      },
      dataJson1,
      dataJson, // lottie实例需要的json数据
      dataJson2,
      dataJson3,
      // anim: '', // lottie实例
      dayOrNight: "",
      mapType: "矢量",
      socket: false,
      socketData: false,
      historyMenu: [],
      menuShow: true,
      modelUrl: "",
      urlsetShow: false,
      MemoryShow: false,
      Memory: "",

      beforeNum: this.getCookie("before"),
      beforeShow: false,

      lrShow: false,
      lrNum: this.getCookie("lr"),

      secMenu: false,
      modelAddress: false,
      houseId: false,
      fullScr: false,
      loading: true, // 地图初次渲染
      initShow: false, //初始化
      is: false,
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
      around: false,
      timer: false,
      timer1: false,
      vrUrl: "https://www.720yun.com/vr/6e2jkrekty3",
    };
  },
  watch: {
    DayNight: {
      deep: true, //深度监听设置为 true
      immediate: true,
      handler: function (val, old) {
        this.dayOrNight = val;
      },
    },
    path(val, oldVal) {
      // alert(val)
      // console.log(val, oldVal, "路由路由路由路由路由");

      this.globalData.allNavShow = false; //关掉总菜单

      // if (window.allModels && window.allModels.length > 0) {
      window[oldVal + "model"] = this.deepClone(window.allModels);
      // }
      // if (window.allLayers && window.allLayers.length > 0) {
      window[oldVal + "layer"] = this.deepClone(window.allLayers);

      if (window[val + "model"] && window[val + "model"].length > 0) {
        let arr = window[val + "model"];
        if (window.allModels.length > 0) {
          let xiangtong = [],
            oldButong = [], //旧的模型和新模型不相同的url
            xinButong = []; //新的模型和就模型不相同的url
          xiangtong = window.allModels.filter(
            (item) => arr.indexOf(item) !== -1
          );

          oldButong = window.allModels.filter(
            (item) => arr.indexOf(item) === -1
          );
          if (oldButong.length > 0) {
            oldButong.forEach((url, idx) => {
              let t = map.getLayer(url, "name");
              if (t) t.remove();
            });
          }
          xinButong = arr.filter(
            (item) => window.allModels.indexOf(item) === -1
          );
          if (xinButong.length > 0) {
            xinButong.forEach((url, idx) => {
              this.drawModel(url);
            });
          }

          window.allModels = xinButong.concat(xiangtong);
        } else {
          arr.forEach((url, idx) => {
            // alert("chogn");
            window.allModels.push(url);
            this.drawModel(url);
          });
        }
      } else if (window[val + "model"] && window[val + "model"].length == 0) {
        if (window.allModels && window.allModels.length > 0) {
          window.allModels.forEach((url, idx) => {
            let t = map.getLayer(url, "name");
            if (t) t.remove();
          });
          window.allModels = [];
        }
      }

      if (window[val + "layer"] && window[val + "layer"].length > 0) {
        let arr = window[val + "layer"];
        if (window.allLayers.length > 0) {
          let xiangtong = [],
            oldButong = [], //旧的模型和新模型不相同的url
            xinButong = []; //新的模型和就模型不相同的url
          xiangtong = window.allLayers.filter(
            (item) => arr.indexOf(item) !== -1
          );

          oldButong = window.allLayers.filter(
            (item) => arr.indexOf(item) === -1
          );
          if (oldButong.length > 0) {
            oldButong.forEach((url, idx) => {
              let t = map.getLayer(url, "name");
              if (t) t.remove();
            });
          }
          xinButong = arr.filter(
            (item) => window.allLayers.indexOf(item) === -1
          );
          if (xinButong.length > 0) {
            xinButong.forEach((url, idx) => {
              this.drawLayer(url);
            });
          }

          window.allLayers = xinButong.concat(xiangtong);
        } else {
          arr.forEach((url, idx) => {
            // alert("chogn");
            window.allLayers.push(url);
            this.drawLayer(url);
          });
        }
      } else if (window[val + "layer"] && window[val + "layer"].length == 0) {
        if (window.allLayers && window.allLayers.length > 0) {
          window.allLayers.forEach((url, idx) => {
            let t = map.getLayer(url, "name");
            if (t) t.remove();
          });
          window.allLayers = [];
        }
      }
      // }
      // console.log(this.globalData.xiapulevel,'111111111')
      if (
        this.globalData.xiapulevel[val] == 1 &&
        this.globalData.xiaPuGrid.length > 0
      ) {
        this.globalData.xiaPuGrid.forEach((countyPolygon, idx) => {
          countyPolygon.show = true;
        });
      } else if (
        this.globalData.xiapulevel[val] != 1 &&
        this.globalData.xiaPuGrid.length > 0
      ) {
        this.globalData.xiaPuGrid.forEach((countyPolygon, idx) => {
          countyPolygon.show = false;
        });
      }
      return;
      if (window.countyModel.length > 0) {
        window.countyModel.forEach((e) => {
          e.remove(true);
        });
        window.countyModel = [];
        window.allModels = [];
      }
      if (window.countyLayer.length > 0) {
        window.countyLayer.forEach((e) => {
          map.removeLayer(e, true);
        });
        window.countyLayer = [];
        window.allLayers = [];
      }

      // ------------------
      if (window[val + "model"]) {
        window[val + "model"].forEach((url, idx) => {
          this.drawModel(url, idx);
        });
      }
      if (window[val + "layer"]) {
        window[val + "layer"].forEach((url, idx) => {
          this.drawLayer(url, idx);
        });
      }
    },

    "globalData.manageShowInMap": function (val) {
      if (window.singleTileLayer.length > 0) {
        window.singleTileLayer.forEach((e) => {
          e.show = val;
        });
      }

      if (window.singleTiles3dLayer.length > 0) {
        window.singleTiles3dLayer.forEach((e) => {
          e.show = val;
        });
      }

      if (this.globalData.tbArr.length > 0) {
        this.globalData.tbArr.forEach((e) => {
          let a = map.getLayer(e, "name");
          if (a) a.show = val;
        });
      }

      let ftbDatas = Object.keys(this.globalData.ftbArr);

      ftbDatas.forEach((e) => {
        if (this.globalData.ftbArr[e].length > 0) {
          this.globalData.ftbArr[e].forEach((graphic) => {
            if (graphic && graphic.state == "added") {
              graphic.show = val;
            }
          });
        }
      });
    },

    // right(val) {
    //   // console.log(val)
    //   let view1 = {
    //     alt: 1136,
    //     heading: Math.random() * 5,
    //     lat: 27.443948,
    //     lng: 117.125481,
    //     pitch: -50,
    //     // pitch: -(Math.random() * (50 - 45) + 45)
    //   },
    //     view2 = {
    //       alt: 1152,
    //       heading: Math.random() * 5,
    //       lat: 27.443478,
    //       lng: 117.123909,
    //       pitch: -50,
    //       // pitch: -(Math.random() * (50 - 45) + 45)
    //     }
    //   val ? map.setCameraView(view1) : map.setCameraView(view2)

    // }
  },
  computed: {
    scenicList() {
      return this.$store.state.index.scenicList;
    },
    xunlan() {
      return this.$store.state.index.xunlan;
    },
    playStatus() {
      return this.$store.state.index.playStatus;
    },
    path() {
      return this.$route.path;
    },
    left() {
      return this.$store.state.index.left;
    },
    right() {
      return this.$store.state.index.right;
    },
    detailshow() {
      return this.$store.state.index.detailshow;
    },
    viewer() {
      return this.$store.state.index.viewer;
    },
    videoUrl() {
      return this.$store.state.index.videoUrl;
    },
    View() {
      return this.$store.state.index.View;
    },
    DayNight() {
      return this.$store.state.index.DayNight;
    },
  },
  created() {
    this.$nextTick(async () => {
      // await this.getAssece();
      this.initMap();
    });
  },
  methods: {
    inputChange(value) {

      this.keyword = value;
    },
    mouseenterFun() {
      this.globalData.showManageList = true;
    },
    showManage() {
      this.globalData.showManageList = !this.globalData.showManageList;

      if (this.globalData.showManageList) {
        this.oldRight = this.right;
        this.$store.commit("index/set", { val: false, key: "right" });
      } else {
        this.$store.commit("index/set", { val: this.oldRight, key: "right" });
      }
    },
    // 清除测量
    clearMeasure() {
      measure.clear();
    },

    // 测面
    onClickArea() {
      measure.stopDraw();
      let area = measure.areaSurface();
    },
    // 测距
    onClickDistance() {
      measure.stopDraw();
      let dis = measure.distanceSurface();
    },
    addAllPolygon() {
      if (this.gridCount < this.allGrid.length) {
        let element = this.allGrid[this.gridCount].info;
        let e = this.allGrid[this.gridCount].element;
        let countyPolygon = this.addPolygonSimple(
          graphicLayer,
          element.value,
          e
        );
        countyPolygon.id =
          element.label +
          element.value.id +
          "Polygon" +
          new Date().getTime() +
          Math.random() * 100000;
        this.globalData.xiaPuGrid.push(countyPolygon);
        this.gridCount++;
        this.gridTimer = requestAnimationFrame(this.addAllPolygon);
      } else {
        cancelAnimationFrame(this.gridTimer);
      }
    },
    async getnavs() {
      await wxpublicGrid({ sign: "wghz" }).then((res) => {
        let obj = res.data;
        if (obj.children && obj.children.length > 0) {
          this.allGrid = [];
          obj.children.forEach((element) => {
            if (element.value.border) {
              let arr = JSON.parse(element.value.border);
              if (typeof arr == "string") return;
              let len = this.getArrayDepth(arr);
              if (len <= 2) {
                this.allGrid.push({
                  info: element,
                  element,
                });
              } else {
                arr.forEach((e, idx) => {
                  this.allGrid.push({
                    info: element,
                    element: e,
                  });
                });
              }
            }
          });
          this.addAllPolygon();
        }
      });
    },
    // 页面无操作20秒
    searchChart() {
      let that = this;
      //三秒自动一次
      var maxTime = 20; // seconds mousemove
      var time = maxTime;
      $("body").on("keydown  mousedown", function (e) {
        time = maxTime; // reset
        that.$store.commit("index/set", { val: false, key: "xunlan" });
      });
      this.timer = setInterval(function () {
        time--;
        if (time == 0 && !this.xunlan) {
          // console.log(that.path,that.playStatus)
          // if (
          //   that.path == "/Overview" ||
          //   (that.path == "/NowScenic" && that.playStatus)
          // )
          //   return;
          that.$store.commit("index/set", { val: true, key: "xunlan" });
          if (that.path != "/NowScenic") {
            that.$router.push({ path: "/NowScenic" });
          }
        }
      }, 1000);
    },
    reflach() {
      window.location.reload();
    },
    windowClose() {
      window.open("about:blank", "_top").close();
    },
    clear() {
      this.$confirm("是否确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          this.setCookie("access-token", false, -1);
          this.$router.push({ path: "/login" });
          // window.location.reload();
        })
        .catch(() => {});
    },
    async getAssece() {
      let time = JSON.parse(this.getCookie("refresh-token"));
      if (!time) return;
      let refresh_token = time.data;
      time = Number(time.dataTime);
      // console.log(this.datedifference(time),'getAssece')
      if (!this.datedifference(time)) {
        await refreshToken({
          tenantId: "208797",
          refresh_token,
          grant_type: "refresh_token",
          scope: "all",
        }).then((res) => {
          this.setCookie("access-token", res.access_token, 7);
          this.setCookie(
            "refresh-token",
            JSON.stringify({
              data: res.refresh_token,
              dataTime: new Date().getTime(),
            }),
            7
          );
        });
      }
      setInterval(() => {
        time = JSON.parse(this.getCookie("refresh-token"));
        if (!time) return;
        let refresh_token = time.data;
        time = Number(time.dataTime);
        if (!this.datedifference(time)) {
          refreshToken({
            tenantId: "208797",
            refresh_token,
            grant_type: "refresh_token",
            scope: "all",
          }).then((res) => {
            this.setCookie("access-token", res.access_token, 7);
            this.setCookie(
              "refresh-token",
              JSON.stringify({
                data: res.refresh_token,
                dataTime: new Date().getTime(),
              }),
              7
            );
          });
        }
      }, 10000);
    },
    setSecMenu(val) {
      this.secMenu = val;
    },
    change(val) {
      this.$store.commit("index/set", {
        key: "DayNight",
        val,
      });
    },
    close() {
      this.$store.commit("index/set", {
        key: "viewer",
        val: {
          list: false,
          key: false,
        },
      }); //开启/关闭编辑模式
      // clearInterval(this.imagesTimer);
    },
    datedifference(time) {
      //计算工作日方法：遍历这两个日期区间的每一个日期，获取他的getDay()
      //分别获取first和last的毫秒数(时间戳)
      let first = new Date().getTime();
      // console.log(first,time)
      var count = true;

      let cha = first - time;
      if (cha >= 5 * 60 * 1000) {
        count = false;
      }
      // console.log(cha)
      return count;
    },
    async initMap() {
      // const _this = this;
      let modelOption = this.getCookie("modelOption");
      if (modelOption) {
        this.modelOption = JSON.parse(modelOption);
      }
      let modelUrl = this.getCookie("modelUrl");
      if (!modelUrl) {
        await getModelAddress().then((res) => {
          this.modelAddress = res.data;
        });
      }
      const map = new mars3d.Map("mars3dContainer", {
        control: {},
        scene: {
          shadows: false, //是否启用日照阴影
          removeDblClick: false, //是否移除Cesium默认的双击事件

          //以下是Cesium.Viewer所支持的options【控件相关的写在另外的control属性中】
          sceneMode: 3, //3等价于Cesium.SceneMode.SCENE3D,

          //以下是Cesium.Scene对象相关参数
          showSun: true, //是否显示太阳
          showMoon: true, //是否显示月亮
          showSkyBox: true, //是否显示天空盒
          showSkyAtmosphere: true, //是否显示地球大气层外光圈
          fog: true, //是否启用雾化效果
          fxaa: true, //是否启用抗锯齿
          backgroundColor: "black",
          //以下是Cesium.Globe对象相关参数
          globe: {
            depthTestAgainstTerrain: false, //是否启用深度监测
            baseColor: "black", //地球默认背景色
            showGroundAtmosphere: true, //是否在地球上绘制的地面大气
            enableLighting: false, //是否显示昼夜区域
          },
          //以下是Cesium.ScreenSpaceCameraController对象相关参数
          cameraController: {
            zoomFactor: 3.0, //鼠标滚轮放大的步长参数
            minimumZoomDistance: 1, //地球放大的最小值（以米为单位）
            maximumZoomDistance: 50000000, //地球缩小的最大值（以米为单位）
            enableRotate: true, //2D和3D视图下，是否允许用户旋转相机
            enableTranslate: true, //2D和哥伦布视图下，是否允许用户平移地图
            enableTilt: true, // 3D和哥伦布视图下，是否允许用户倾斜相机
            enableZoom: true, // 是否允许 用户放大和缩小视图
            enableCollisionDetection: true, //是否允许 地形相机的碰撞检测
          },
        },
        basemaps: [
          {
            name: "天地图影像",
            icon: "img/basemaps/tdt_img.png",
            type: "tdt",
            layer: "img_d",
            show: true,
          },

          {
            name: "谷歌影像",
            // icon: "img/basemaps/google_img.png",
            type: "xyz",
            url: "https://npmap.3dy.me/map/xpmap/{z}/{x}/{y}.jpg",
            chinaCRS: mars3d.ChinaCRS.GCJ02,
            show: true,
          },

          {
            hasZIndex: true,
            zIndex: 1,
            name: "ArcGIS影像",
            type: "arcgis",
            url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
            enablePickFeatures: false,
            show: true,
          },
        ],
        terrain: {
          url: "https://data.mars3d.cn/terrain",
          show: true,
        },
        layers: [],
      });

      window.map = map;
      // window.map.hasTerrain = true;
      window.viewer = map.viewer;

      this.removeInputAction(); //禁用右键拖拽功能
      this.tiltEventTypes(); // 右键功能改Ctrl+左键
      process.env.NODE_ENV === "production" ? this.initModel() : "";
      graphicLayer = new mars3d.layer.GraphicLayer();
      measure = new mars3d.thing.Measure({
        // 可设置文本样式
        label: {
          color: "#ffffff",
          font_family: "楷体",
          font_size: 20,
          background: false,
        },
      });
      rotatePoint = new mars3d.thing.RotatePoint({
        direction: false, // 方向 true逆时针，false顺时针
        time: 50, // 给定飞行一周所需时间(单位 秒)，控制速度
        // autoStopAngle: 360, //到达指定角度后自动停止
      });
      map.addThing(rotatePoint);
      map.addLayer(graphicLayer);
      map.addThing(measure);
      this.bindMapDemo();
      // this.searchChart()
      this.linkSocket();
      map.getBasemaps().forEach((e) => {
        if (e.options.name == "谷歌影像") {
          e.show = true;
        }

        if (e.options.name == "天地图影像") {
          e.show = true;
        }

        if (e.options.name == "ArcGIS影像") {
          e.show = false;
        }
      });

      map.on(
        mars3d.EventType.click,
        (event) => {
          rotatePoint.stop();
          // console.log(event);
          var cartesian3 = viewer.scene.globe.pick(
            viewer.camera.getPickRay(event.position),
            viewer.scene
          );
          let ellipsoid = viewer.scene.globe.ellipsoid;
          let cartographic = ellipsoid.cartesianToCartographic(cartesian3);
          let lat = Cesium.Math.toDegrees(cartographic.latitude);
          let lng = Cesium.Math.toDegrees(cartographic.longitude);
          let alt = cartographic.height;
          console.log([lng, lat, alt]);
          let Cartesian = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesian3
          );
        },
        this
      );

      map.on(mars3d.EventType.dblClick, (event) => {
        // console.log(event)
        var cartesian = viewer.scene.globe.pick(
          viewer.camera.getPickRay(event.position),
          viewer.scene
        );
        rotatePoint.start(cartesian);
      });

      map.on(mars3d.EventType.renderError, function (event) {
        window.location.reload();
      });

      // landscapevr().then(res => {
      //   // this.initModel(res.data.modelUrl)
      //   this.vrUrl = res.data.url;
      // })
      this.loading = false;

      // map.openFlyAnimation({
      //   // 开场动画
      //   duration1: 4,
      //   easingFunction1: Cesium.EasingFunction.CUBIC_IN_OUT,
      //   callback: function () {
      // 动画播放完成后回调
      // getBGMenu().then((res) => {
      //   this.globalData.menuArr = res.data;

      this.initShow = true;

      this.getnavs();
      // });
      // map.setCameraView(this.View).then(result => {

      // });

      //   },
      // });

      return map;
    },
    closeVideo() {
      this.$store.commit("index/set", { val: false, key: "videoUrl" });
    },
    // 右键功能改Ctrl+左键
    tiltEventTypes(earth) {
      window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [
        Cesium.CameraEventType.MIDDLE_DRAG,
        Cesium.CameraEventType.PINCH,
        {
          eventType: Cesium.CameraEventType.LEFT_DRAG,
          modifier: Cesium.KeyboardEventModifier.CTRL,
        },
        {
          eventType: Cesium.CameraEventType.RIGHT_DRAG,
        },
      ];
    },
    // 禁用右键拖拽功能
    removeInputAction(earth) {
      window.viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.RIGHT_DRAG
      );
    },
    // bindMapDemo() {
    //   let arr = map.getContextMenu();
    //   let arr1 = [];
    //   arr.forEach((e) => {
    //     if (
    //       e.text == "图上量算" ||
    //       e.text == "特效效果" ||
    //       e.text == "场景设置" ||
    //       e.text == "查看此处坐标"
    //     ) {
    //       arr1.push(e);
    //     }
    //   });
    //   map.bindContextMenu(arr1);
    // },
    bindMapDemo() {
      let arr = map.getContextMenu();
      const mapContextmenuItems = [
        {
          text: "本地配置",
          icon: "fa fa-gear",
          // 也支持直接的svg代码
          children: [
            {
              text: "模型地址设置",
              icon: "fa fa-send-o",
              callback: this.setModelUrl, // 也支持“方法名称”方式(如config.json中配置时)
            },
            {
              text: "本地模型配置",
              icon: "fa fa-send-o",
              callback: this.setMemory, // 也支持“方法名称”方式(如config.json中配置时)
            },
          ],
        },
      ];
      let arr1 = [...arr, ...mapContextmenuItems];
      map.bindContextMenu(arr1);

      // map.openContextMenu(new mars3d.LngLatPoint(116.266845, 30.967094, 1000.4).toCartesian())
    },
    setModelUrl() {
      this.urlsetShow = true;
    },
    subUrl() {
      let modelUrls = [];
      if (this.modelUrl) {
        modelUrls = this.modelUrl.split();
      }
      this.setCookie("modelUrl", JSON.stringify(modelUrls), 7000);
      this.initModel();
      this.urlsetShow = false;
    },
    reUrl() {
      this.modelUrl = "";
      this.setCookie("modelUrl", false, -1);
      this.urlsetShow = false;
    },
    reMemory() {
      this.modelOption = this.baseOption;
      this.setCookie("modelOption", false, -1);
      this.MemoryShow = false;
    },
    setMemory() {
      this.MemoryShow = true;
    },
    subMemory() {
      this.setCookie("modelOption", JSON.stringify(this.modelOption), 7000);
      this.initModel();
      this.MemoryShow = false;
    },
    initModel() {
      let modelUrl = this.getCookie("modelUrl");
      if (modelUrl) modelUrl = JSON.parse(modelUrl);

      if (window.tiles3dLayer && window.tiles3dLayer.length > 0) {
        window.tiles3dLayer.forEach((e) => {
          e.remove();
        });
        window.tiles3dLayer = [];
      }

      if (modelUrl && modelUrl.length > 0) {
        modelUrl.forEach((url, idx) => {
          let tiles3dLayer = new mars3d.layer.TilesetLayer(
            Object.assign(
              {
                name: "模型" + idx,
                url,
              },
              this.modelOption
            )
          );
          map.addLayer(tiles3dLayer);
          window.tiles3dLayer.push(tiles3dLayer);
        });
      } else {
        if (this.modelAddress && this.modelAddress.length > 0) {
          this.modelAddress.forEach((url, idx) => {
            let tiles3dLayer = new mars3d.layer.TilesetLayer(
              Object.assign(
                {
                  name: "模型" + idx,
                  url,
                },
                this.modelOption
              )
            );
            map.addLayer(tiles3dLayer);
            window.tiles3dLayer.push(tiles3dLayer);
          });
        }
      }
    },

    linkSocket() {
      let http =
        process.env.NODE_ENV === "production"
          ? "wss://" + window.location.host + "/api/websocket/"
          : "ws://" + "192.168.110.30:8080" + "/api/websocket/";
      // let http = "wss://" + "szxc.3dy.me/api/websocket/";
      let userid = decodeURIComponent(this.getCookie("userInfo"))
        .replace(/%22/g, '"')
        .replace(/%2C/g, ",");
      userid = JSON.parse(userid);
      userid = userid.user_id;
      socket = new WebSocket(http + userid + "/" + new Date().getTime());

      socket.onopen = () => {
        console.log("websocket已链接");
      };
      socket.onmessage = (e) => {
        // console.log(e)

        let info;

        if (e.data && typeof (e.data == "string")) {
          info = JSON.parse(e.data);
        }
        // console.log(info, "infoinfoinfo");
        // if (!e.data || info.data) return;
        if (info.type == "rcsb") {
          this.globalData.rcsbData = info.data;
          this.bbData = info.data;
          this.$refs.allMp.play();
          setTimeout(() => {
            this.bbData = false;
          }, 15000);
        } else if (info.type == "3") {
          let videoArr = [];

          if (info.data.videoUrl && info.data.videoUrl.length > 0) {
            info.data.videoUrl.forEach((el, idx) => {
              videoArr[idx] = el + "?vframe/jpg/offset/1";
            });
          }
          info.data.showImages = [...videoArr, ...info.data.photoUrl];
          this.globalData.socketData = info.data;
        }
      };

      socket.onclose = () => {
        console.log("websocket已关闭");
      };
    },

    test111() {
      let obj = {
        time: Math.random() * 10,
        createTime: 1705544935312,
        gridName: "网格一",
        id: 1747808254817239000,
        monitorName: "监控一",
        photoUrl: [
          "kauuyZFz2fZcgD7ubfV0zvO4i",
          "https://xpwghoss.3dy.me/FsRVUsGcIYWHGcW0GwyGv5bRouEN",
          "https://xpwghoss.3dy.me/Fqpnh7vrqESNN63u5PnUfxSds5bb",
        ],
        position:
          '{"alt":209.54610809289878,"lat":26.530635033698687,"lng":119.84523315323779}',
        showImages: [
          "https://xpwghoss.3dy.me/d325179f6a7a375c0d79f09abb5db4ff.mp4?vframe/jpg/offset/1",
          "https://zhcj.3dy.me/api/file/preview-video/20230830/1693378820964.mp4?vframe/jpg/offset/1",
          "kauuyZFz2fZcgD7ubfV0zvO4i",
          "https://xpwghoss.3dy.me/FsRVUsGcIYWHGcW0GwyGv5bRouEN",
          "https://xpwghoss.3dy.me/Fqpnh7vrqESNN63u5PnUfxSds5bb",
        ],
        typeName: "在矫人员",
        username: "李国伟",
        videoUrl: [
          "https://xpwghoss.3dy.me/d325179f6a7a375c0d79f09abb5db4ff.mp4",
          "https://zhcj.3dy.me/api/file/preview-video/20230830/1693378820964.mp4",
        ],
        views:
          '{"lat": 26.529537, "lng": 119.844639, "alt": 405, "heading": 20, "pitch": -45}',
      };

      this.globalData.socketData = obj;
    },
  },

  async mounted() {
    getDefaultViews().then((res) => {
      if (Object.keys(res.data).length == 0) return;
      this.$store.commit("index/set", {
        val: JSON.parse(res.data),
        key: "View",
      });
    });

    // setTimeout(() => {
    //   this.bbData = {
    //     azimuth: "开往未知方向",
    //     createTime: "2025-01-24 15:00:00",
    //     deviceNumber: "2eb4f83a5ba04f0fa16c948c4b8c9ef3",
    //     id: "1882723054612697089",
    //     licensePlate: "浙59SVN",
    //     monitorId: "195222226845687",
    //     monitorName: "里澳村前段",
    //     photoUrl: "/pic?=d7ei703i10cd*73a-d5108a--22cd0c9d6592aiid=",
    //     position: '{"alt":90,"lat":26.7027698536,"lng":119.8960942097}',
    //     rcType: 2,
    //     typeId: "359282011553692",
    //     typeName: "车辆识别",
    //     views:
    //       '{"lat":26.702746,"lng":119.896303,"alt":808,"heading":0,"pitch":-90}',
    //   };
    //   this.$refs.allMp.play();
    //       setTimeout(() => {
    //         this.bbData = false;
    //       }, 15000);
    // }, 5000);
  },
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;

  .rightIcon {
    position: fixed;
    top: 30px;
    width: 20px;
    height: 20px;
    z-index: 50;
  }

  #mars3dContainer {
    width: 100%;
    height: calc(100% + 70px);
    position: relative;
  }
}

.DailyLifeBtn {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 120px;
  transform: translate(-50%, 0);
  transition: right 0.3s ease-in-out;
  cursor: pointer;

  .groupbutton-button {
    width: 120px;
    height: 30px;
    background: linear-gradient(
      to right,
      rgba(40, 40, 40, 0) 0%,
      rgba(40, 40, 40, 0.5) 50%,
      rgba(40, 40, 40, 0) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    border: unset;

    .text {
      font-size: 24px;
      font-family: 045-SSYiFeiTi;
      font-weight: 400;
      color: #ffffff;
      line-height: 41px;
      text-shadow: 0px 2px 2px rgba(1, 180, 255, 0.2),
        0px 4px 8px rgba(1, 180, 255, 0.4);
      // -webkit-text-stroke: 1px #FBC545;
      // text-stroke: 1px #FBC545;
      background: linear-gradient(0deg, #99ff89 0%, #59ffa2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .TopLine {
      width: calc(100% - 10px);
      height: 2px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background: linear-gradient(
        to right,
        rgba(1, 180, 255, 0) 0%,
        rgba(89, 255, 162, 0.5) 50%,
        rgba(1, 180, 255, 0) 100%
      );
    }

    .bottomLine {
      width: calc(100% - 10px);
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background: linear-gradient(
        to right,
        rgba(1, 180, 255, 0) 0%,
        rgba(89, 255, 162, 0.5) 50%,
        rgba(1, 180, 255, 0) 100%
      );
    }
  }

  .groupbutton-button:hover {
    background: rgba(35, 35, 35, 0.65);
  }
}

.yan_bo_shi {
  position: fixed;
  right: 110px;
  width: 90px;
  height: 90px;
  bottom: 80px;
  z-index: 28;
  cursor: pointer;

  img {
    width: 90px;
    height: 90px;
    // position: absolute;
    // left: 10px;
    // top: 10px;
  }
}

.yan_bo_shi::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background: #ffb22b;
  border-radius: 50%;
  opacity: 0.2;
  animation: breath 0.6s linear 0.5s infinite alternate; // 呼吸动画实现
}

@keyframes breath {
  // 关键帧规则
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.linkpageBtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 410px;
  bottom: 80px;
  z-index: 11;
  cursor: pointer;

  img {
    margin-bottom: 10px;
  }

  .btn {
    background: linear-gradient(90deg, #1069ff 0%, #01b4ff 100%);
    box-shadow: 0px 5px 20px 0px rgba(25, 151, 255, 0.5),
      0px 0px 10px 0px rgba(25, 149, 255, 0.5);
    border-radius: 18px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 0px rgba(243, 126, 0, 0.5);
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      box-shadow: 0px 2px 0px 0px rgba(77, 197, 102, 0.5);
    }
  }

  .btn1 {
    background: linear-gradient(
      90deg,
      rgba(249, 115, 33, 1) 0%,
      rgba(254, 174, 49, 1) 100%
    );
    box-shadow: 0px 5px 20px 0px rgba(255, 98, 12, 0.5),
      0px 0px 10px 0px rgba(255, 98, 12, 0.5);
    border-radius: 18px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 0px rgba(243, 126, 0, 0.5);
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      box-shadow: 0px 2px 0px 0px rgba(243, 126, 0, 0.5);
    }
  }

  .btn2 {
    background: linear-gradient(
      90deg,
      rgba(24, 167, 53, 1) 0%,
      rgba(3, 205, 117, 1) 100%
    );
    box-shadow: 0px 5px 20px 0px rgba(0, 216, 165, 0.5),
      0px 0px 10px 0px rgba(0, 216, 165, 0.5);
    border-radius: 18px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 0px rgba(77, 197, 102, 0.5);
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      box-shadow: 0px 2px 0px 0px rgba(77, 197, 102, 0.5);
    }
  }
}

.arrowG {
  // width: 53px;
  height: 57px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 16;
  transform: translate(-50%, 0);
  // background-image: url("../assets/static/2222.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  cursor: pointer;
}

.newReportNews {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ff3149;
  position: fixed;
  pointer-events: none;
  width: 600px;
  overflow: hidden;
  height: 46px;
  background: linear-gradient(
    to right,
    rgba(11, 11, 28, 0) 10%,
    rgba(12, 16, 28, 0.75) 20%,
    rgba(12, 16, 28, 1) 50%,
    rgba(12, 16, 28, 0.75) 80%,
    rgba(11, 11, 28, 0) 100%
  );
  // text-align: center;
  line-height: 46px;
  z-index: 16;
  left: 50%;
  top: 80px;
  transform: translate(-50%, 0);

  .newText {
    animation: news 6s linear infinite;
  }
}

@keyframes news {
  0% {
    transform: translate(100%, 0);
    /*从0度开始*/
  }

  100% {
    transform: translate(0%, 0);
    /*360度结束*/
  }
}

.xsj {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  z-index: -5;
}

.xbj {
  position: fixed;
  left: 0;
  display: none;
  z-index: -5;
}
.allMp {
  position: fixed;
  left: 0;
  display: none;
  z-index: -5;
}

.xjz {
  position: fixed;
  left: 0;
  display: none;
  z-index: -5;
}

.videoBox {
  width: 100vw;
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;

  .closeBtn {
    width: 43px;
    height: 43px;
    position: absolute;
    right: 18px;
    top: 18px;
    z-index: 1;
  }
}

.tuichu {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  transition: all 0.3s;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  img:nth-child(1) {
    opacity: 0.75;
  }

  img:nth-child(2) {
    opacity: 0.75;
  }

  img:nth-child(3) {
    opacity: 0.75;
  }
}

.test111 {
  width: 200px;
  height: 100px;
  z-index: 121;
  position: fixed;
  left: 10px;
  top: 10px;
  background: white;
}

.manageBtn {
  // margin-left: 12px;
  width: 110px;
  height: 30px;
  background: rgba(17, 58, 98, 0.81);
  border: 1px solid #2678d5;
  border-radius: 4px;
  padding: 0 10px;
  box-sizing: border-box;
}

.radioName {
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 12px;
  color: #cde4f8;
  width: 52px;
  // line-height: 30px;
  font-style: italic;
  background: linear-gradient(to top, rgba(138, 199, 255, 1) 15%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.manageIcon {
  font-size: 12px;
  color: rgba(138, 199, 255, 1);
}
.btnPad {
  padding-left: 10px;
}
</style>
