<template>
  <el-collapse-transition>
    <div class="column wrap hid newTypes">
      <el-timeline style="max-width: 100%">
        <el-timeline-item
          v-for="(item, idx) in obj"
          :key="idx"
          :timestamp="idx"
          placement="top"
        >
          <span
            @click="showThis(val)"
            :class="
              val.checked
                ? 'checkName mousePoint blue'
                : 'checkName mousePoint'
            "
            v-for="val in item"
            :key="val"
            >{{ val.name }}</span
          >
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-collapse-transition>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, watch, inject } from "vue";
export default {
  name: "BuildingType2",
  components: {},
  props: {
    obj: {
      default: {},
    },
    borderShow: {
      default: true,
    },
    title: "",
  },

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({}); // 数据
    const globalData = inject("globalData");
    const showThis = (val) => {
      val.checked = !val.checked;
      console.log(val)
      context.emit("showThis", val);
    };
    onMounted(async () => {});

    return {
      // 变量
      state,
      //方法
      showThis,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.newTypes {
  padding-left: 14px;
  box-sizing: border-box;
  .checkName {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #7d98b3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;

    &:hover {
      opacity: 0.6;
    }
  }

  .blue {
    color: rgba(1, 180, 255, 1) !important;
  }
}
</style>
