import store from "@/store";
import { globalData } from "@/utils/global.js";
// 定义一个深拷贝函数  接收目标target参数
export function deepClone(target) {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === "object") {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]));
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}
// 根据经纬度获取高度
/**
 * @func  isEmpty
 * @desc  判断是否为空
 * @param {object} obj 需要判断的对象
 * @return {boolean} 是否为空
 */
export function isEmpty(obj) {
  if (
    typeof obj === "undefined" ||
    obj === undefined ||
    obj == null ||
    obj === ""
  ) {
    return true;
  }
  return false;
}
/**
 * @func  isFunction
 * @desc  判断是否为方法
 * @param {object} it 判断的对象
 * @return {boolean} 是否
 */
export function isFunction(it) {
  return Object.prototype.toString.call(it) === "[object Function]";
}
/**
 * @func  isArray
 * @desc  判断是否为数组
 * @param {object} it 判断的对象
 * @return {boolean} 是否
 */
export function isArray(/* anything */ it) {
  if (typeof it !== "object") {
    return false;
  }
  if (!(it instanceof Array)) {
    return false;
  }
  if (it.length === 0) {
    return false;
  }

  return true;
}

/**
 * @func  clone
 * @desc  对象复制
 * @param {object} o 复制的对象
 * @return {object} 复制的对象
 */
export function clone(/* anything */ o) {
  if (!o || typeof o !== "object" || this.isFunction(o)) {
    return o; // anything
  }
  if (o.nodeType && "cloneNode" in o) {
    return o.cloneNode(true);
  }
  if (o instanceof Date) {
    // Date
    return new Date(o.getTime());
  }
  let r, i, l, s, name;
  if (this.isArray(o)) {
    // array
    r = [];
    for (i = 0, l = o.length; i < l; ++i) {
      if (i in o) {
        r.push(this.clone(o[i]));
      }
    }
  } else {
    r = o.constructor ? new o.constructor() : {};
  }
  let empty = {};
  for (name in o) {
    s = o[name];
    if (
      !(name in r) ||
      (r[name] !== s && (!(name in empty) || empty[name] !== s))
    ) {
      r[name] = this.clone(s);
    }
  }

  return r; // Object
}

// 时间转换
export function formatTimes(startTime, endTime) {
  let diff = endTime - startTime;
  let str = "";
  // 毫秒化天
  let d = Math.floor(diff / (24 * 3600 * 1000));
  if (d > 0) {
    str += d + "天";
  }
  // 毫秒化小时
  let rh = diff % (24 * 3600 * 1000);
  let h = Math.floor(rh / (3600 * 1000));
  h = h < 10 ? "0" + h : h;
  if (h > 0 || d > 0) {
    str += h + ":";
  } else {
    str += "00:";
  }
  // 毫秒化分钟
  let rm = rh % (3600 * 1000);
  let m = Math.floor(rm / (60 * 1000));
  m = m < 10 ? "0" + m : m;
  if (m > 0 || d > 0 || h > 0) {
    str += m + ":";
  } else {
    str += "00:";
  }

  // 毫秒化秒
  let rs = rm % (60 * 1000);
  let s = Math.round(rs / 1000);
  s = s < 10 ? "0" + s : s;
  if (s > 0 || d > 0 || h > 0 || m > 0) {
    str += s;
  } else {
    str += "00";
  }
  return str;
}

export function setCookie(key, value, t) {
  var oDate = new Date(); //创建日期对象
  oDate.setDate(oDate.getDate() + t); //设置过期时间
  document.cookie = key + "=" + value + ";expires=" + oDate.toGMTString(); //设置cookie的名称，数值，过期时间
}

export function getCookie(key) {
  var arr1 = document.cookie.split("; "); //将cookie按“; ”分割，数组元素为： cookie名=cookie值
  for (var i = 0; i < arr1.length; i++) {
    //分割数组里的每个元素
    var arr2 = arr1[i].split("="); //按照“=”分割
    if (arr2[0] == key) {
      //如果数组的第一个元素等于给定的cookie名称
      return decodeURI(arr2[1]); //返回翻译编码后的cookie值
    }
  }
}

// elementUI 时间快捷选项
export function elDateTime() {
  var arr = [
    {
      text: "昨天6时到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        const start2 = new Date(`${y}-${m}-${r} 06:00`);
        return [start2, end];
      })(),
    },
    {
      text: "当前整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = end.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前1小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 1);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前2小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          end.getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 2);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "前3小时整点到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          end.getHours()
        );
        start.setTime(start.getTime() - 3600 * 1000 * 3);
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        var h = start.getHours();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        h = h + "";
        h.length == 1 ? "0" + h : h;
        const start2 = new Date(`${y}-${m}-${r} ${h}:00`);
        return [start2, end];
      })(),
    },
    {
      text: "最近6小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 6);
        return [start, end];
      })(),
    },
    {
      text: "最近12小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 12);
        return [start, end];
      })(),
    },
    {
      text: "最近24小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        return [start, end];
      })(),
    },
    {
      text: "最近48小时",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 48);
        return [start, end];
      })(),
    },
    {
      text: "最近一周",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      })(),
    },
    {
      text: "最近一个月",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      })(),
    },
    {
      text: "最近三个月",
      value: (() => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      })(),
    },
  ];
  const time = new Date();
  if (time.getHours() >= 6) {
    arr.unshift({
      text: "今天6时到现在",
      value: (() => {
        const end = new Date();
        const start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate()
        );
        var y = start.getFullYear();
        var m = start.getMonth();
        var r = start.getDate();
        m += 1;
        m = m + "";
        m.length == 1 ? "0" + m : m;
        r = r + "";
        r.length == 1 ? "0" + r : r;
        const start2 = new Date(`${y}-${m}-${r} 06:00`);
        return [start2, end];
      })(),
    });
  }
  return arr;
}
//wgs84_to_gcj02.js文件
//地标 转 国测 常量
var x_PI = (3.14159265358979324 * 3000.0) / 180.0;
// console.log(x_PI);
var PI = 3.1415926535897932384626;
var a = 6378245.0; //卫星椭球坐标投影到平面地图坐标系的投影因子。
var ee = 0.00669342162296594323; //椭球的偏心率。

//判断是否在国内，在中国国内的经纬度才需要做偏移
function out_of_china(lng, lat) {
  return (
    lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false
  );
}

//转化经度
function transformlng(lng, lat) {
  var ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * PI) +
      300.0 * Math.sin((lng / 30.0) * PI)) *
      2.0) /
    3.0;
  return ret;
}

//转化纬度
function transformlat(lng, lat) {
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}

//wgs84 to gcj02   地球坐标系 转 火星坐标系
function wgs84_to_gcj02(lng, lat) {
  lng = Number(lng);
  lat = Number(lat);
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;

    return [mglng, mglat];
  }
}

//gcj02 to wgs84  火星坐标系 转 地球坐标系
function gcj02_to_wgs84(lng, lat) {
  lng = Number(lng);
  lat = Number(lat);
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    return [lng * 2 - mglng, lat * 2 - mglat];
  }
}
//  数组总和
export function sumArr(arr) {
  let sum = 0;
  arr.forEach((e) => {
    sum += Number(e);
  });
  return sum;
}
// 数组总和数组
export function getSumArr(arr) {
  let sum = 0;
  arr.forEach((e) => {
    sum += Number(e);
  });
  let arr1 = [];
  arr.forEach((e) => {
    arr1.push(sum);
  });
  return arr1;
}
// 数组最大值
export function bigNum(arr) {
  let big = Number(arr[0]);
  arr.forEach((e) => {
    big = big > Number(e) ? big : Number(e);
  });
  return big;
}

// 数组最大值组成的数组
export function bigArr(arr) {
  let big = Number(arr[0]);
  let arr1 = [];
  arr.forEach((e) => {
    big = big > Number(e) ? big : Number(e);
  });
  arr.forEach((e) => {
    arr1.push(big);
  });
  return arr1;
}
// 获取服务器时间
function getServerTime() {
  var xhr = null;
  if (window.XMLHttpRequest) {
    xhr = new window.XMLHttpRequest();
  } else {
    // ie
    xhr = new ActiveObject("Microsoft");
  }

  xhr.open("GET", "/", false); //false不可变
  xhr.send(null);
  var date = xhr.getResponseHeader("Date");
  return new Date(date);
}

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  // console.log(fn)
  // console.log(typeof fn)
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
export const Throttle = (fn, t) => {
  let last;
  let timer;
  let interval = t || 500;
  return function () {
    let args = arguments;
    let now = new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
};

function flyHome() {
  window.map.setCameraView(store.state.index.View);
}

function drawWms(option, zIndex = 10) {
  let JsonLayer = new mars3d.layer.WmsLayer({
    id: option.type,
    url: "https://live.3dy.me/SEA/wms",
    layers: "SEA:" + option.type,
    name: option.type,
    zIndex,
    opacity: 0.5,
    getFeatureInfoParameters: {
      feature_count: 10,
      INFO_FORMAT: "text/plain",
    },
    parameters: {
      tiled: true,
      VERSION: "1.1.0",
      transparent: true,
      FORMAT: "image/png",
      tileWidth: 64,
      tileHeight: 64,
    },
    show: option.show,
    // flyTo: true,
    attr: option,
  });
  map.addLayer(JsonLayer);
  return JsonLayer;
}

// 广告牌  加 label
function addBillboardPrimitive(
  graphicLayer,
  position,
  image,
  attr,
  clampToGround
) {
  const primitive = new mars3d.graphic.BillboardPrimitive({
    position,
    zIndex: 10,
    style: {
      image,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      // visibleDepth: false,
      clampToGround,
      // label: {
      //   text: attr.text,
      //   font_size: 14,
      //   font_family: "Source Han Sans CN",
      //   color: "rgba(255, 255, 255, 1)",
      //   outline: false,
      //   background: true,
      //   backgroundColor: "rgba(32, 40, 53, 0.9)",

      //   pixelOffsetY: -50,
      // },
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1000000),
    },
    attr,
  });
  graphicLayer.addGraphic(primitive);
  return primitive;
}
// 水波纹
function addWaterCircle(graphicLayer, position, color = "#FFFFC7", count = 1) {
  // // 画水波纹
  const primitive = new mars3d.graphic.CirclePrimitive({
    position,
    visibleDepth: true,
    style: {
      radius: 80,
      clampToGround: true,
      flat: true,
      material: mars3d.MaterialUtil.createMaterial(
        mars3d.MaterialType.CircleWave,
        {
          color,
          count, // 圆圈数量
          speed: 10,
          gradient: 0.1,
          flat: true,
          // clampToGround: true,
        }
      ),
    },
    attr: {
      remark: "示例11",
    },
  });
  graphicLayer.addGraphic(primitive);
  return primitive;
}

//扩散圆 单个
function addCircle(graphicLayer, position, color = "#FFFFC7") {
  const graphic = new mars3d.graphic.CirclePrimitive({
    position,
    style: {
      radius: 80,
      materialType: mars3d.MaterialType.ScanLine,
      color,
      speed: 5,
      // clampToGround: true, //  是否贴地
    },
    attr: {
      remark: "示例5",
    },
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

// label函数
function addLabelEntity(graphicLayer, info) {
  const graphic = new mars3d.graphic.LabelEntity({
    name: "label函数",
    position: new mars3d.LngLatPoint(120.214181, 26.924334, 0),
    style: {
      text: info.address,
      font_size: 14,
      font_family: "Source Han Sans CN",
      color: "rgba(1, 180, 255, 1)",
      outline: false,
      background: true,
      backgroundColor: "rgba(40,49,66,0.9)",
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0.0,
        7000000
      ),
      clampToGround: true,
      // 高亮时的样式（默认为鼠标移入，也可以指定type:'click'单击高亮），构造后也可以openHighlight、closeHighlight方法来手动调用
      // highlight: {
      //   font_size: 35,
      // },
    },
    attr: info,
  });
  graphic.on(mars3d.EventType.click, function (event) {
    console.log("监听graphic，单击了矢量对象", event);
  });
  graphicLayer.addGraphic(graphic);
}

function addDivGraphic(
  graphicLayer,
  html,
  position,
  info,
  near = 0,
  far = 10000000,
  offsetY = 0,
  zIndex = 5,
  clampToGround,
  horizontalOrigin = Cesium.HorizontalOrigin.CENTER,
  verticalOrigin = Cesium.VerticalOrigin.BOTTOM,
  offsetX = 0
) {
  const graphic = new mars3d.graphic.DivGraphic({
    id: info.id,
    position,
    zIndex,
    style: {
      html,
      horizontalOrigin,
      verticalOrigin,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(near, far), // 按视距距离显示
      scaleByDistance: true,
      clampToGround: clampToGround
        ? clampToGround
        : !position[2] && !position.alt,
      offsetY,
      offsetX,
      disableDepthTestDistance: 10000000000,
    },
    allowDrillPick: false,
    attr: info,
  });
  // console.log(clampToGround?clampToGround:(!position[2] && !position.alt))
  // if (attr) {
  //   graphic.id = attr.name + attr.id
  // }

  graphicLayer.addGraphic(graphic);
  return graphic;
}

// 迁徙函数    // 迁徙线
function allEntitys(
  graphicLayer,
  center,
  cities,
  flag,
  color = "rgba(0, 231, 161, 1)"
) {
  // console.log(center, cities,'211111111111111111')
  // if(isArray(center) && !center[2]){
  //   center[2] = 0
  // }

  // if(!isArray(center) && center.alt){
  //   center.alt = 0
  // }

  // center = [120.214777, 26.920312, 0];
  // const cities = [{
  //     name: "六安市",
  //     lon: 120.214425,
  //     lat: 26.922681
  //   },
  //   {
  //     name: "六安市",
  //     lon: 120.214722,
  //     lat: 26.92218
  //   },
  //   {
  //     name: "六安市",
  //     lon: 120.215556,
  //     lat: 26.922062
  //   },
  //   {
  //     name: "六安市",
  //     lon: 120.216285,
  //     lat: 26.922665
  //   },
  //   {
  //     name: "六安市",
  //     lon: 120.21624,
  //     lat: 26.923464
  //   },
  // ];

  const lineMaterial = mars3d.MaterialUtil.createMaterial(
    mars3d.MaterialType.LineFlow,
    {
      image: require("@/assets/mars/line-color-aqua.png"),
      color,
      speed: 10,
    }
  );
  for (let i = 0; i < cities.length; i++) {
    const item = cities[i];
    // ---
    // const startPoint = Cesium.Cartesian3.fromDegrees(120.214777, 26.920312, 0)
    // const endPoint = Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0)
    // const positions = mars3d.PolyUtil.getLinkedPointList(startPoint, endPoint, 20000, 50) // 计算曲线点
    // ---

    const thisPoint = Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 1);
    const positions = mars3d.PolyUtil.getLinkedPointList(
      center, // 开始节点
      thisPoint, // 结束节点
      40000, //曲率
      100 //点集数量
    ); // 计算曲线点
    // ---
    const primitive = new mars3d.graphic.PolylinePrimitive({
      id: item.id + flag + "line",
      positions: positions,
      color,
      style: {
        width: 2,
        material: lineMaterial, // 动画线材质
      },
      // style: {
      //   width: 4,
      //   color: "rgba(72, 233, 243, 1)",
      //   opacity: 0.5,
      //   materialType: mars3d.MaterialType.LineFlowColor,
      //   materialOptions: {
      //     color: "#00ffff",
      //     speed: 50,
      //     percent: 0.15,
      //     alpha: 0.2
      //   }
      // },
      attr: item,
    });
    // primitive.bindPopup(`合肥 - ${item.name}`).openPopup();
    graphicLayer.addGraphic(primitive);
  }
}
// 画点
function addPointEntity(
  graphicLayer,
  position,
  attr,
  color = "rgba(16, 105, 255, 1)"
) {
  const graphic = new mars3d.graphic.PointEntity({
    position,
    style: {
      color,
      pixelSize: 12,
      outlineColor: "#ffffff",
      outlineWidth: 2,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0.0,
        1000000000
      ),
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}
// 画箭头
function allArrow(graphicLayer, positions) {
  const graphic = new mars3d.graphic.StraightArrow({
    positions,
    style: {
      color: "rgba(235, 84, 76, 1)",
      opacity: 0.6,
      outline: false,
      outlineWidth: 3,
      outlineColor: "#ffffff",
      clampToGround: true,
    },
    attr: {
      remark: "示例1",
    },
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}
// 画箭头
function allArrow1(graphicLayer, positions) {
  // 画箭头
  const primitive000 = new mars3d.graphic.PolylinePrimitive({
    positions,
    style: {
      width: 20,
      clampToGround: true,
      material: mars3d.MaterialUtil.createMaterial(
        mars3d.MaterialType.PolylineArrow,
        {
          image: require("@/assets/images/s3.png"),
          color: "rgba(235, 84, 76, 1)",
          speed: 10,
        }
      ), // 动画线材质
    },
  });
  graphicLayer.addGraphic(primitive000);
  return primitive000;
}

function getCount(arr) {
  var list = [];
  var num = 0;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] instanceof Array) {
      for (var j = 0; j < arr[i].length; j++) {
        list.push(arr[i][j]);
      }
    }
  }
  if (list.length) {
    num = 1;
    num += getCount(list);
  }
  return num;
}

// 划区域
// function addPolygon(graphicLayer, info, positions) {
//   // console.log(JSON.parse(info.border))PolygonEntity
//   const graphic = new mars3d.graphic.PolygonEntity({
//     positions: JSON.parse(info.border),
//     // hasOpacity: true,
//     style: {
//       color: info.color ? info.color : "rgb(1, 180, 255)",
//       opacity: info.opacity ? info.opacity : 0.01,
//       outline: true,
//       outlineWidth: 2,
//       outlineColor: info.color ? info.color : "rgb(1, 180, 255)",
//       clampToGround: true,
//       highlight: {
//         opacity: 0.2,
//         color: "rgb(1, 180, 255)",
//       },
//     },
//     attr: info,
//   });
//   graphicLayer.addGraphic(graphic); // 还可以另外一种写法: graphic.addTo(graphicLayer)
//   return graphic;
// }

function addPolygon(graphicLayer, info, element, color) {
  let position = JSON.parse(info.border);
  let graphic;
  graphic = new mars3d.graphic.PolygonPrimitive({
    positions: isArray(element) ? element : position,
    id: new Date().getTime(),
    style: {
      color: color ? color : info.color ? info.color : "#9D8AC5",
      opacity: info.opacity ? info.opacity : 0.01,
      outline: true,
      outlineWidth: 2,
      outlineColor: color ? color : info.color ? info.color : "#9D8AC5",
      clampToGround: true,
      // highlight: {
      //   opacity: 0,
      //   color: 'rgba(255,255,255)'
      // }
    },
    attr: info,
  });
  graphicLayer.addGraphic(graphic); // 还可以另外一种写法: graphic.addTo(graphicLayer)
  return graphic;
}

function createDom(name, type = 1, info = false) {
  let dom = "";
  if (type == 1) {
    let img1 = require("@/assets/img/b13.png");
    let img2 = require("@/assets/img/b12.png");
    dom = `<div class="comBoxPin1">
    <div class="comContent">
      <img src="${img1}" alt="">
      <div class="nameBox">${name}</div>
      <img src="${img2}" alt="">
    </div>
  </div>`;
  } else if (type == 2) {
    let img1 = require("@/assets/img/b16.png");
    let img2 = require("@/assets/img/b15.png");
    dom = `<div class="comBoxPin1">
    <div class="comContent">
      <img src="${img1}" alt="">
      <div class="nameBox1">${name}</div>
      <img src="${img2}" alt="">
    </div>
  </div>`;
  } else if (type == 3) {
    let img1 = require("@/assets/img/b13.png");
    let img2 = require("@/assets/dc/" + info.typeName + ".png");
    dom = `<div class="comBoxPin1">
    <div class="comContent">
      <div class="nameBox2">
      <img src="${img1}" alt="">
        ${name}
      </div>
      <img style="transform: translate(0, -6px);"  src="${img2}" alt="">
    </div>
  </div>`;
  } else if (type == 4) {
    let img1 = require("@/assets/img/b13.png");
    let img2 = require("@/assets/img/D14.png");
    dom = `<div class="comBoxPin1">
    <div class="comContent">
      <div class="nameBox2">
      <img src="${img1}" alt="">
        ${name}
      </div>
      <img class='o0' src="${img2}" alt="">
    </div>
  </div>`;
  } else if (type == 5) {
    let img1 = require("@/assets/img/b13.png");
    let img2 = require("@/assets/img/b12.png");
    dom = `<div class="comBoxPin1">
    <div class="comContent">
      <div class='count'>${info.countNum}</div>
      <img src="${img1}" alt="">
      <div class="nameBox">${name}</div>
      <img src="${img2}" alt="">
    </div>
  </div>`;
  }
  return dom;
}

function addLineGraphic(
  graphicLayer,
  positions,
  attr,
  color = "rgba(16, 105, 255, 1)",
  width = 6
) {
  const graphic = new mars3d.graphic.PolylinePrimitive({
    positions,
    style: {
      width,
      color,
      materialOptions: {
        color,
        speed: 5,
      },
      clampToGround: true,
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function createDom1(name, type = 1, info = false, arr = []) {
  let href = window.location.href;
  let dom;
  let url3 = require("@/assets/images/d1.png");
  let image = require("@/assets/images/d5.png");
  let image1;
  let url4 =
    name.indexOf("隔离点") != -1
      ? require("@/assets/pin/p2.png")
      : name.indexOf("接种点") != -1
      ? require("@/assets/pin/p6.png")
      : require("@/assets/pin/p4.png");
  let flag = "隔离点";

  let url8;
  if (info && info.avatar) {
    image1 = info.avatar;
  }
  if (type == 5) {
    url8 = require(`@/assets/pin/${name}.png`);
  }

  if (type == 1) {
    dom = `<div class="flexDiv">
      <div class="valligeCountPin">
            <div class="valligeEntity">
              <div class="valligeName">
                <div class="topLine"></div>
                ${name}
                <div class="bottomLine"></div>
              </div>
            </div >`;
    if (arr && arr.length > 0) {
      dom += `<div style="margin-bottom:5px;">`;
    }
    if (arr && arr.length > 0) {
      arr.forEach((e) => {
        dom += `<div class="textRow">`;
        if (e.image) {
          `<img class="iconImg" src="${e.image}" alt="">`;
        }

        dom += `<span>${e.name}：</span>
                  <span class="bigText">${e.count}</span>
                </div>`;
      });
    }
    if (arr && arr.length > 0) {
      dom += `</div>`;
    }

    dom += `</div>
    <div class="verticalLine"></div>
              </div>`;
  } else if (type == 2) {
    dom = `<div class="flexDiv"><div class="valligeCountPin">
            <div class="valligeEntity">
              <div class="valligeName">
                <div class="topLine"></div>
                <span style='font-size:18px;'>${name}</span> 
                <div class="bottomLine"></div>
              </div>
            </div >`;
    if (arr && arr.length > 0) {
      dom += `<div style="margin-bottom:5px;">`;
    }
    if (arr && arr.length > 0) {
      arr.forEach((e) => {
        dom += `<div class="textRow">`;
        // if (e.image) {
        //   `<img class="iconImg" src="${e.image}" alt="">`
        // }

        dom += `<span>${e.name}：</span>
                  <span class="bigText">${e.count}</span>
                </div>`;
      });
    }
    if (arr && arr.length > 0) {
      dom += `</div>`;
    }

    dom += `</div><div class="verticalLine"></div>
    </div>`;
  } else if (type == 0) {
    dom = `
    <div class="addPinBox">
      <div class="pinName">${name}</div>
      <img class="bottomImg" src="${url3}" alt="">
    </div>
  `;
  } else if (type == 3) {
    // 人员小标记点
    dom = `
    <div class="farPeoplePin">
      <div class="smallBox"></div>
    </div>
  `;
  } else if (type == 4) {
    // 人员普通标记点
    dom = `
    <div class="nearPeoplePin">
      <img src="${image1}">
    </div>
  `;
  } else if (type == 5) {
    // 人员大标记点
    dom = `
  <div class="activePeopleBox activePeopleBox${name}">
    <div class="nameAndInfo">
      <span>${info.name}</span>
      <span>${
        href.indexOf("/DeepGrid") != -1 ? info.familyUser.length + "口人" : name
      }</span>
    </div>
    <div class="activePeoplePin">
      <img class="img" src="${image1}">
    </div>
    <img src="${url8}">
    <div class="smallBox">
        <div></div>
      </div>
  </div>
  `;
  } else if (type == 6) {
    // 隔离点  接种点   核酸点
    dom = `
    <div class="pointPinBox">
      <div class="pinName pinName${flag}">${name}</div>
      <img class="iconImg" src="${url4}" alt="">
      <img class="bottomImg" src="${image}" alt="">
    </div>
  `;
  } else if (type == 7) {
    let img1 = require(`@/assets/dy/t实有户数.png`);
    let img2 = require(`@/assets/dy/t实有人口.png`);
    let img3 = require(`@/assets/dy/rs.png`);
    // 党建网格  小区标记点
    dom = `<div class="flexDivParty">
  <div class='party_pin_box'>
    <div class="party_pin">
      <div class="pinTitle">
        <div class="TopLine"></div>
          ${name}
        <div class="bottomLine"></div>
      </div>
    </div>
  </div><div class="verticalLine"></div>
  </div>
  `;
    // <img style='transform: translate(0px, -15px);' src="${image}">
  } else if (type == 8) {
    // 党建网格  小区标记点
    let back = require("@/assets/static/hs.png");
    dom = `
  <div class='redAlertPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>百花新村小区红色驿站</div>
  </div>
  `;
  } else if (type == 9) {
    // 党建网格  小区标记点
    let back = require("@/assets/static/lm.png");
    dom = `
  <div class='redAlertPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>百花新村小区红色驿站</div>
    
  </div>
  `;
  } else if (type == 10) {
    // 部件  小区标记点
    let back = require("@/assets/static/bj.png");
    dom = `
  <div class='partPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>
      <div class='bigTitle'>
          ${name}
      </div>
      <div class='intr'>
          <div>
          部件类型：
          </div>
          <div>
          服务部件
          </div>
      </div>
      <div class='intr'>
          <div>
           负责人：
          </div>
          <div>
            王某某/13799314040
          </div>
      </div>
    </div>
  </div>
  `;
  } else if (type == 11) {
    // 部件  小区标记点
    let back = require("@/assets/static/bj.png");
    dom = `
  <div class='placePin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>
      <div class='bigTitle'>
          ${name}
      </div>
    </div>
  </div>
  `;
  } else if (type == 12) {
    // 老人体育
    let back = require("@/assets/static/p1.png");
    dom = `
  <div class='oldManPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>百花新村小区红色驿站</div>
    
  </div>
  `;
  } else if (type == 13) {
    dom = `<div class="comBoxPin">
    <div class="comContent">
      <div class="nameBox">
      ${name}
      </div>
      <div class="line">
      </div>
    </div>
  </div>`;
  } else if (type == 14) {
    let img = require("@/assets/pin/camera.svg");
    dom = ` <div class="mars3d-camera-content">
    <img class="mars3d-camera-img" src="${img}" >
  </div>
  <div class="mars3d-camera-line" ></div>
  <div class="mars3d-camera-point"></div>
`;
  } else if (type == 15) {
    // 网格巡查中标记点
    let back = require("@/assets/new/c4.png");
    let titleBack = require("@/assets/new/c33.png");
    dom = `
  <div class='partPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>
      <div class='bigTitle'>
      <img src="${titleBack}" > ${name}
      </div>
      <div class='intr'>
          <div>
          巡查人：
          </div>
          <div>
          ${info.name}
          </div>
      </div>
      <div class='intr'>
          <div>
          巡查分类：
          </div>
          <div>
          ${info.typeName}
          </div>
      </div>
    </div>
  </div>
  `;
  } else if (type == 16) {
    // 网格巡查中标记点
    let back = require("@/assets/new/c4.png");
    let titleBack = require("@/assets/new/c22.png");
    let obj = info.userList.find((a) => {
      return a.id == info.userId;
    });
    let createUser = obj ? obj.name : "--";
    dom = `
  <div class='partPin'>
    <img src="${back}">
    <div class='linkLine'></div>
    <div class='contentBox'>
      <div class='bigTitle'>
      <img src="${titleBack}" > ${name}
      </div>
      <div class='intr'>
          <div>
          创建人：
          </div>
          <div>
          ${createUser}
          </div>
      </div>
      <div class='intr'>
          <div>
          关联事件：
          </div>
          <div>
          ${info.reportName}
          </div>
      </div>
    </div>
  </div>
  `;
  }

  return dom;
}

function addScrollWall(
  graphicLayer,
  positions,
  info,
  color = "rgb(252,165,65)"
) {
  // 走马灯围墙效果
  const scrollWall = new mars3d.graphic.ScrollWall({
    positions,
    style: {
      diffHeight: 100, // 高度
      color,
      style: 2, // 效果2，默认是1
      speed: 10,
    },
    attr: info,
  });
  graphicLayer.addGraphic(scrollWall);
  return scrollWall;
}

function chooseCss(url) {}

function addDemoGraphic14(
  graphicLayer,
  positions,
  attr,
  color = "rgba(89,249,255,0.8)",
  width = 5
) {
  var graphic = new mars3d.graphic.PolylineEntity({
    positions,
    style: {
      width,
      clampToGround: true,
      materialType: mars3d.MaterialType.LineFlow,
      materialOptions: {
        color: hexToRgb(color, 0.6),
        image: getViteUrl("../assets/mars/line-tarans.png"),
        speed: 2,
      },
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function addDemoGraphic1(
  graphicLayer,
  positions,
  attr,
  color = "rgba(89,249,255,0.8)",
  width = 5
) {
  const graphic = new mars3d.graphic.PolylinePrimitive({
    positions,
    style: {
      width,
      materialType: mars3d.MaterialType.LineTrail,
      materialOptions: {
        color,
        speed: 3,
      },
      clampToGround: true,
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);

  // initGraphicManager(graphic)

  return graphic;
}

function addDemoGraphic4(
  graphicLayer,
  positions,
  attr,
  color = "rgba(16, 105, 255, 1)",
  width = 5,
  clampToGround = true,
  dashLength = 8,
  addHeight = 0
) {
  const graphic = new mars3d.graphic.PolylineEntity({
    positions,
    style: {
      width,
      clampToGround,
      addHeight,
      materialType: mars3d.MaterialType.PolylineDash,
      materialOptions: {
        color,
        dashLength,
      },
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);

  return graphic;
}
function addLinematerialType(
  graphicLayer,
  positions,
  attr,
  color = "rgba(16, 105, 255, 1)",
  width = 5
) {
  let image = getViteUrl("../assets/mars/1111.png");

  const graphic = new mars3d.graphic.PolylinePrimitive({
    positions,
    style: {
      width,
      color,
      materialType: mars3d.MaterialType.LineFlow,
      materialOptions: {
        color,
        image,
        speed: 2,
        axisY: false,
        repeat: new Cesium.Cartesian2(20.0, 1.0),
      },
      clampToGround: true,
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function addDivLightPoint(
  graphicLayer,
  position,
  color = "#f33349",
  attr = {}
) {
  const graphic = new mars3d.graphic.DivLightPoint({
    position,
    style: {
      color,
    },
    attr,
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function timestampToTimeD(timestamp) {
  let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-"; //年
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-"; //月
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " "; //日
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  let strDate = Y + M + D + h + m + s;
  return strDate;
}

// 计算 js计算某个时间前几秒
function subtractSeconds(endTime, seconds) {
  var result = new Date(endTime);
  result.setSeconds(result.getSeconds() + seconds);
  let str = timestampToTimeD(result);
  return str;
}

// 截取对象
function intercept(objct, start, end) {
  /*参数1为对象, 参数2 为开始截取的位置 参数3为结束截取的位置*/
  /*第一步 利用entries 将对象转换成二维数组*/
  /*第二步 利用slice 进行截取*/
  let Index_0 = Object.entries(objct).slice(start, end);

  /*第三步 利用fromEntries 将二维数组再转换为对象*/
  let Index_1 = Object.fromEntries(Index_0);

  /*返回对象*/
  return Index_1;
}
// 把时间格式里的横杆和冒号和空格去掉
function removeTimeSeparators(timeStr) {
  return timeStr.replace(/[-: ]/g, "");
}
// js比较两个时间大小
function compareTime(time1, time2) {
  if (time1 > time2) {
    return false;
  } else if (time1 < time2 || time1 == time2) {
    return true;
  }
}
// 秒数转成 时分秒
function convertSecondsToHMS(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  return hours + "小时" + minutes + "分钟" + remainingSeconds + "秒";
}

function getCameraData(obj) {
  let arr = [];
  for (let index = 0; index < obj.length; index++) {
    if (obj[index].children && obj[index].children.length > 0) {
      let arr1 = getCameraData(obj[index].children);
      arr = [...arr, ...arr1];
    }
    if (!obj[index].isGrid) arr.push(obj[index]);
  }
  return arr;
}

function removeGraphic(graphicLayer, id, type, showBool = false) {
  let arr = [];
  graphicLayer.eachGraphic((graphic) => {
    console.log(graphic.id)
    if (graphic && graphic.state == "added" && graphic.id.indexOf(id) != -1) {
      if (type == "remove") {
        arr.push(graphic);
      } else if (type == "show") {
        graphic.show = showBool;
      }
    }
  });

  if (type == "remove" && arr.length > 0) {
    arr.forEach((e) => {
      e.remove(true);
    });
  }
}

function getAnnotationData(obj) {
  let arr = [];
  for (let index = 0; index < obj.length; index++) {
    if (obj[index].children && obj[index].children.length > 0) {
      let arr1 = getAnnotationData(obj[index].children);
      arr = [...arr, ...arr1];
    }
    if (obj[index].showOnMap && obj[index].fileType == "e")
      arr.push(obj[index]);
  }

  // console.log(arr)
  return arr;
}

function getAnnotationShowData(obj) {
  let arr = [];
  for (let index = 0; index < obj.length; index++) {
    if (obj[index].children && obj[index].children.length > 0) {
      let arr1 = getAnnotationShowData(obj[index].children);
      arr = [...arr, ...arr1];
    }
    if (obj[index].fileType == "e") arr.push(obj[index]);
  }

  // console.log(arr)
  return arr;
}

function addDistanceMeasure(
  graphicLayer,
  positions,
  attr,
  color = "rgba(16, 105, 255, 1)",
  show = true
) {
  const graphic = new mars3d.graphic.DistanceMeasure({
    positions,
    style: {
      color,
      outlineColor: color,
      clampToGround: true,
    },
    label: {
      opacity: show ? 1 : 0,
      background: show,
    },
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function addAreaMeasure(
  graphicLayer,
  positions,
  attr,
  color = "rgba(16, 105, 255, 1)",
  show = true
) {
  const graphic = new mars3d.graphic.AreaMeasure({
    positions,
    style: {
      color: color,
      opacity: 0.1,
      outline: true,
      outlineColor: color,
      outlineWidth: 4.0,
      clampToGround: true,
    },
    label: {
      opacity: show ? 1 : 0,
      background: show,
    },
  });
  graphicLayer.addGraphic(graphic);
  return graphic;
}

function getArrayDepth(arr) {
  if (!Array.isArray(arr)) {
    return 0;
  }
  return 1 + Math.max(...arr.map((item) => getArrayDepth(item)));
}
function drawModel(url, idx = 0, label) {
  let tiles3dLayer = new mars3d.layer.TilesetLayer(
    Object.assign(
      {
        name: url,
        url,
      },
      globalData.modelOption
    )
  );
  map.addLayer(tiles3dLayer);
  window.countyModel.push(tiles3dLayer);
}

function drawLayer(url, cutoutRectangle, label) {
  let layer = new mars3d.layer.XyzLayer({
    url,
    hasZIndex: true,
    zIndex: 6,
    name: url,
    subdomains: "1234",
    flyTo: true,
    // rectangle: { xmin: 120.01517101240361, xmax: 120.02169904341721, ymin: 26.85438503651868, ymax: 26.855448003152052 }
  });
  window.countyLayer.push(layer);
  map.addLayer(layer);
}

export function drawHeatLayer(arrPoints, name, level) {
  if (!arrPoints) return;
  let positions = [];
  arrPoints.forEach((e) => {
    // if (e[globalData.heatObj[name]] <= 0) return;
    positions.push({
      lng: e.lng,
      lat: e.lat,
      value: e[globalData.heatObj[name]],
      // value: e.value,
    });
  });
  let max = 0,
    min = 0;
  positions.forEach((item) => {
    max = max > item.value ? max : item.value;
    min = min < item.value ? min : item.value;
  });
  const heatLayer = new mars3d.layer.HeatLayer({
    positions,
    // name: "heatLayer",
    name,
    max,
    min,
    // 以下为热力图本身的样式参数，可参阅api：https://www.patrick-wied.at/static/heatmapjs/docs.html
    heatStyle: {
      radius: positions.length > 60 ? 3 : 200,
      blur: 0.85,
      maxOpacity: 0.8,
      minOpacity: 0,
      gradient: {
        0.9: "red",
        0.8: "orange",
        0.7: "yellow",
        0.5: "blue",
        0.3: "green",
      },
    },
    // 以下为矩形矢量对象的样式参数
    style: {
      arc: true, // 是否为曲面
      height: 100,
      // opacity: 1.0
      // clampToGround: true,
    },
    // flyTo: true,
  });
  map.addLayer(heatLayer);
  // heatLayer.show = globalData.heatShow;
  return heatLayer;
}

export function drawReportHeatLayer(arrPoints, name, level) {
  // console.log(arrPoints)
  if (!arrPoints) return;
  let positions = [];
  arrPoints.forEach((e) => {
    // console.log(e.position)
    if (!e.position || e.position == "false") return;
    let position;
    position = e.position.split(",");
    position = gcj02_to_wgs84(position[0], position[1]);
    positions.push({
      lng: position[0],
      lat: position[1],
      value: e.num,
      // value: e.value,
    });
  });
  let max = 0,
    min = 0;
  positions.forEach((item) => {
    max = max > item.value ? max : item.value;
    min = min < item.value ? min : item.value;
  });

  // console.log(positions,max,min)
  const heatLayer = new mars3d.layer.HeatLayer({
    positions,
    // name: "heatLayer",
    name,
    max,
    min,
    // 以下为热力图本身的样式参数，可参阅api：https://www.patrick-wied.at/static/heatmapjs/docs.html
    heatStyle: {
      radius: positions.length > 60 ? 3 : 200,
      blur: 0.85,
      maxOpacity: 0.8,
      minOpacity: 0,
      gradient: {
        0.9: "red",
        0.8: "orange",
        0.7: "yellow",
        0.5: "blue",
        0.3: "green",
      },
    },
    // 以下为矩形矢量对象的样式参数
    style: {
      arc: true, // 是否为曲面
      height: 100,
      // opacity: 1.0
      // clampToGround: true,
    },
    // flyTo: true,
  });
  map.addLayer(heatLayer);
  // heatLayer.show = globalData.heatShow;
  return heatLayer;
}

function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    // 如果结果对象中还没有当前key对应的组，则创建一个新组
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = [];
    }
    // 将当前元素添加到其对应的组中
    result[currentValue[key]].push(currentValue);
    return result;
  }, {});
}

export const functions = {
  groupBy,
  drawModel,
  drawLayer,
  drawHeatLayer,
  drawReportHeatLayer,

  getArrayDepth,
  addDistanceMeasure,
  addAreaMeasure,
  getAnnotationData,
  getAnnotationShowData,
  removeGraphic,
  getCameraData,
  timestampToTimeD,
  subtractSeconds,
  intercept,
  removeTimeSeparators,
  compareTime,
  convertSecondsToHMS,
  addLinematerialType,
  addDivLightPoint,
  addDemoGraphic14,
  addDemoGraphic4,
  addDemoGraphic1,
  drawWms,
  chooseCss,
  isEmpty,
  isFunction,
  isArray,
  clone,
  formatTimes,
  elDateTime,
  setCookie,
  getCookie,
  deepClone,
  wgs84_to_gcj02,
  gcj02_to_wgs84,
  sumArr,
  getSumArr,
  bigNum,
  bigArr,
  getServerTime,
  addBillboardPrimitive,
  addWaterCircle,
  addCircle,
  addLabelEntity,
  addDivGraphic,
  allEntitys,
  allArrow1,
  allArrow,
  addPolygon,
  createDom,
  createDom1,
  flyHome,
  addScrollWall,
  addLineGraphic,
  addPointEntity,
  Debounce,
  Throttle,
};
