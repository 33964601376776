import { globalData } from "@/utils/global";
import { get, post } from "./config";

// var url2 = 'https://zhxp.3dy.me/api/'
let uvaUrl =
  process.env.NODE_ENV === "development"
    ? "/adi"
    : "http://xpxn.fjsdywlkj.cn:10800/";

window.tiles3dLayer = []; // 全局模型列表
window.singleTileLayer = []; // 监测地图模块   切片列表
window.singleTiles3dLayer = []; // 监测地图模块   模型列表
export default {
  // getTenant: (data) =>
  //     get(`http://192.168.110.58:8081/api/blade-system/tenant/get-tenant`, data),

  // getTenant: (data) =>
  //   get(`https://zhcj.3dy.me/api/blade-system/tenant/get-tenant`, data),

  // getTenant: (data) =>
  //   get(
  //     process.env.NODE_ENV === "development"
  //       ? "http://192.168.110.47:8080/api/blade-system/tenant/get-tenant"
  //       : `https://zhcj.3dy.me/api/blade-system/tenant/get-tenant`,
  //     data
  //   ),
  // getTenant: (data) => get(process.env.NODE_ENV === 'development' ? 'http://192.168.110.36:8711/api/blade-system/tenant/get-tenant' : `https://zhcj.3dy.me/api/blade-system/tenant/get-tenant`, data),
  captcha: (data) => get(`${url2}blade-auth/oauth/captcha`, data),
  login: (data) =>
    post(
      `${url2}blade-auth/oauth/bg/token?tenantId=${data.tenantId}&username=${data.username}&password=${data.password}&grant_type=captcha&scope=all&type=account&openId=${data.openId}&avatar=${data.avatar}&nickname=${data.nickname}`,
      {},
      {
        "Captcha-Code": data.code,
        "Captcha-Key": data.key,
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
      }
    ),
  getUserInfoCode: ({ id, tenantId, roleId }) =>
    post(
      url2 +
        `/blade-auth/oauth/wechat/getToken?roleId=${
          roleId ? roleId : ""
        }&id=${id}&tenantId=${tenantId}&userType=3`
    ),
  refreshToken: (data) =>
    post(
      `${url2}blade-auth/oauth/bg/token?tenantId=${data.tenantId}&refresh_token=${data.refresh_token}&grant_type=refresh_token`,
      data,
      {
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
        "Tenant-Id": `${data.tenantId}`,
      }
    ),

  // QSDWMC+like+%27芹头村%27

  getByCodeqh: (data) =>
    get(`${url2}blade-system/dict-biz/getByCode?code=dpCode`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getLocationTypeDict: (data) =>
    get(`${url2}bg/city/monitor/getLocationTypeDict`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getCityId: (data) =>
    get(
      `https://geoapi.qweather.com/v2/city/lookup?location=${data}&key=0fd0f4fa21f34713ad4b655206310fd7`
    ),
  get3DWeather: (data) =>
    get(
      `https://api.qweather.com/v7/weather/3d?location=${data}&key=0fd0f4fa21f34713ad4b655206310fd7`
    ),
  getWeather: (data) => get(`${url2}blade-desk/weather/data`, data),

  ptzStart: (data) => {
    if (!data.direction) {
      return new Promise((resolve, reject) => {
        resolve();
      });
    } else {
      return get(
        `${
          data.url ? data.url : globalData.IP
        }/api/bg/city/monitor/iscPtzStart`,
        data
      );
    }
  }, // 开启云台
  ptzStop: (data) => {
    if (!data.direction) {
      return new Promise((resolve, reject) => {
        resolve();
      });
    } else {
      return get(
        `${data.url ? data.url : globalData.IP}/api/bg/city/monitor/iscPtzStop`,
        data
      );
    }
  }, // 停止云台
  // presetMove: (data) => post(`https://open.ys7.com/api/lapp/device/preset/move`, data), // 调用预置点
  presetAdd: (data) =>
    post(
      `${data.url ? data.url : globalData.IP}/api/lapp/device/preset/add`,
      data
    ), // 添加预置点
  presetClear: (data) =>
    post(
      `${data.url ? data.url : globalData.IP}/api/lapp/device/preset/clear`,
      data
    ), // 清除
  addressGet: (data) =>
    post(
      `${data.url ? data.url : globalData.IP}/api/lapp/v2/live/address/get`,
      data
    ), // 获取播放地址
  // capture: (data) => post("https://open.ys7.com/api/lapp/device/capture", data), // 设备抓拍图片

  capture: (data) =>
    get(
      `${
        data.url ? data.url : globalData.IP
      }/api/bg/city/monitor/iscManualCapture`,
      data,
      {
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
      }
    ), // 设备抓拍图片
  //  松山社区测试接口--------------------
  getModelAddress: (data) =>
    get(`${url2}blade-desk/grid/getModelAddress`, data, {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    }),
  getChart: (data) => get(`${url2}bg/city/monitor/getChart`, data),
  getCarChart: (data) =>
    get(`${url2}bg/city/gcc/getChart?monitorTypeName=卡口监控`, data),
  // 获取工程车通行列表
  getCarList: (data) =>
    get(`${url2}bg/city/gcc/getList?monitorTypeName=卡口监控`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getDtilesUrl: (data) =>
    get(`${url2}bg/flight/getDtilesUrl`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  submitWarnDisposal: (data) =>
    post(`${url2}wechat/city/event/submitWarnDisposal`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getOnlyMonitorList: (data) =>
    get(`${url2}bg/city/monitor/getOnlyMonitorList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  // 获取监控对应工程车列表
  getGccMonitorList: (data) =>
    get(`${url2}bg/city/gcc/getGccMonitorList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getHistoryVideoStream: (data) =>
    post(`${url2}bg/flight/getHistoryVideoStream`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 获取摄像头列表
  getGccMonitorCount: (data) =>
    get(
      `${url2}bg/city/gcc/getGccMonitorCount?monitorTypeName=卡口监控`,
      data,
      {
        "content-type": "application/json;charset=UTF-8",
      }
    ),
  // 获取规划路径
  getPlanLine: (data) =>
    get(
      `https://restapi.amap.com/v3/direction/driving?key=164b29a8a0abeedb05bdb9052916e2d8&origin=${data.origin}&destination=${data.destination}&waypoints=${data.waypoints}`
    ),
  // 根据车牌获取通行记录
  getCarDetail: (data) => get(`${url2}bg/city/gcc/getCarDetail`, data),
  getMenuCount: (data) => get(`${url2}bg/city/event/getMenuCount`, data),
  getGridMenuCount: (data) =>
    get(`${url2}bg/city/event/getGridMenuCount`, data),
  getGccMenuCount: (data) => get(`${url2}bg/city/event/getGccMenuCount`, data),
  getAiMenuCount: (data) => get(`${url2}bg/city/event/getAiMenuCount`, data),
  getFlightMenuCount: (data) =>
    get(`${url2}bg/city/event/getFlightMenuCount`, data),

  eventFilterProject: (data) => get(`${url2}bg/city/event/fileList`, data),
  getEventDetail: (data) => get(`${url2}bg/city/monitor/getEventDetail`, data),
  getMonitorEvent: (data) =>
    get(`${url2}bg/city/monitor/getMonitorEvent`, data),
  getByCode: (data) => get(`${url2}blade-system/dict-biz/getByCode`, data),
  getEventTypeOther: (data) => get(`${url2}bg/city/event/getEventType`, data),
  presetList: (data) => get(`${url2}ss/sspreset/list`, data),
  removeVideo: (data) =>
    post(`https://time.3dy.me/api/album-record/removeVideo`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  delCollect: (data) =>
    post(`${url2}bg/city/monitor/delCollect/${data.id}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  collect: (data) =>
    post(`${url2}bg/city/monitor/collect/${data.id}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getMonitor: (data) =>
    get(`${url2}blade-desk/monitoringequipment/getMonitor`, data), //  获取实时token
  getVideoAddress: (data) =>
    post(
      `${url2}blade-desk/monitoringequipment/getLargeScreenMonitorAddress`,
      data,
      {
        "content-type": "application/json;charset=UTF-8",
      }
    ),

  getvideos: (data) =>
    post(`https://time.3dy.me/api/album-record/videos`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getVideoSize: (data) =>
    get(`https://time.3dy.me/api/album-record/getVideoSize`, data),
  // 根据车牌获取通行记录
  getCarPage: (data) =>
    get(`${url2}bg/city/gcc/getCarPage`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getCarPageV2: (data) =>
    get(`${url2}bg/city/gcc/getCarPageV2`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  updateWarnPosition: (data) =>
    post(`${url2}bg/city/event/update`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  // 预置点添加
  // createSeaPtz: (data) => post(`${url2}ss/sspreset/save`, data, {
  //   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
  // }),

  createSeaPtz: (data) =>
    post(
      `${data.url ? data.url : globalData.IP}/api/bg/city/monitor/iscAddPreset`,
      data,
      {
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
      }
    ),

  // 预置点删除
  // deleteSeaPtz: (data) => get(`${url2}ss/sspreset/delete`, data, {
  //   'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
  // }),
  deleteSeaPtz: (data) =>
    post(
      `${data.url ? data.url : globalData.IP}/api/bg/city/monitor/iscDelPreset`,
      data,
      {
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
      }
    ),

  presetMove: (data) =>
    get(
      `${
        data.url ? data.url : globalData.IP
      }/api/bg/city/monitor/iscPtzToPreset`,
      data
    ), // 调用预置点
  getLastDetail: (data) => get(`${url2}bg/city/monitor/getLastDetail`, data),
  getWarnListV2: (data) =>
    get(`${url2}bg/city/event/getWarnListV2`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  //获取摄像头统计信息
  getMonitorCount: (data) =>
    get(`${url2}bg/city/event/getMonitorCount`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  //获取监控的预警列表
  getMonitorEventV2: (data) =>
    get(`${url2}bg/city/monitor/getMonitorEventV2`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 获取预警列表的图表数据
  getListChart: (data) =>
    get(`${url2}bg/city/event/getListChart`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 无人机事件详情
  flightEventDetail: (data) =>
    get(`${url2}bg/linkPatrol/flightEventDetail`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  // 无人机日志列表
  flightLogList: (data) =>
    get(`${url2}bg/linkPatrol/flightLogList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 无人机巡查列表
  flightList: (data) =>
    get(`${url2}bg/linkPatrol/flightList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getLinkCheckTypes: (data) =>
    get(`${url2}blade-system/dict/dictionary`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getvideosFliter: (data) =>
    post(`https://time.3dy.me/api/album-record/v2/videos`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 项目筛选
  filterProject: (data) =>
    get(`${url2}bg/linkPatrol/filterProject`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // 改造视频监控列表
  monitorList: (data) =>
    get(`${url2}bg/linkPatrol/monitorList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getGridList: (data) => get(`${url2}bg/city/monitor/getGridList`, data),
  getGridListV2: (data) => get(`${url2}bg/city/monitor/getGridListV2`, data),

  getMonitorType: (data) => get(`${url2}bg/city/monitor/getMonitorType`, data),
  getDeviceList: (data) => get(`${url2}bg/city/monitor/getDeviceList`, data),
  // monitorDetail: (data) => get(`${url2}bg/city/monitor/monitorDetail`, data),
  // monitorDetail: (data) =>
  //   get(`${url2}bg/city/monitor/monitorDetailV2`, data),
  monitorDetail: (data) =>
    post(`${url2}bg/city/monitor/monitorDetailV3`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getAnalysis: (data) => get(`${url2}bg/city/chart/getAnalysis`, data),
  getSimpleGrid: (data) => get(`${url2}bg/city/monitor/getSimpleGrid`, data),
  getSimpleList: (data) => get(`${url2}bg/city/monitor/getSimpleList`, data),
  getEventTypeOther: (data) => get(`${url2}bg/city/event/getEventType`, data),
  eventPage: (data) => get(`${url2}/bg/city/event/page`, data),
  eventExport: () => {
    return `${url2}bg/city/event/export`;
  },

  changePtz: (data) =>
    get(
      `${url2}bg/city/monitor/iscPtzToPreset?cameraIndexCode=4aa653bdf19b410f8647c83704b67a38&number=255`,
      data
    ),
  // -----------------结束----------------------------------

  //------------------新  无人机接口--------------------------
  // flightAttendCount: (data) => get(`${url2}bg/flight/flightAttendCount`, data),
  flightAttendList: (data) => get(`${url2}bg/flight/flightAttendList`, data),
  flightPlanList: (data) => get(`${url2}bg/flight/flightPlanList`, data),
  flightEventDetail: (data) => get(`${url2}bg/flight/flightEventDetail`, data),
  getGrassrootViews: (data) =>
    get(`${url2}blade-desk/grid/getGrassrootViews`, data),
  checkMapPwd: (data) =>
    post(`${url2}blade-user/checkMapPwd`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getAnalysisV2: (data) => get(`${url2}bg/city/chart/getAnalysisV2`, data),
  // iscBatchPlayAddress: (data) =>
  //   post(`${url2}bg/city/monitor/iscBatchPlayAddress`, data, {
  //     "content-type": "application/json;charset=UTF-8",
  //   }),

  saveVideoConfig: (data) =>
    post(`${url2}ss/sspreset/saveVideoConfig`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getListSelect: (data) => get(`${url2}bg/city/event/getListSelect`, data),
  getMonitorConfig: (data) =>
    get(`${url2}bg/city/monitor/getMonitorConfig`, data),
  connectionVideo: (data) =>
    post(`${url2}bg/flight/connectionVideo/${data.id}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  continueVideo: (data) =>
    post(`${url2}bg/flight/continueVideo/${data.id}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  closeVideo: (data) =>
    post(`${url2}bg/flight/closeVideo/${data.id}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  getMonitorConfig: (data) =>
    get(`${url2}bg/city/monitor/getMonitorConfig`, data),
  getRealTimeFlight: (data) => get(`${url2}bg/flight/getRealTimeFlight`, data),
  // 3D放大
  iscPtzSelZoom: (data) =>
    post(
      `${
        data.url ? data.url : globalData.IP
      }/api/bg/city/monitor/iscPtzSelZoom`,
      data,
      {
        "content-type": "application/json;charset=UTF-8",
      }
    ),

  // bg/city/event/getMenuCount

  // -------------振坤--------------------
  bgData: (data) => get(`${url2}bg/gridpatrol/bgData`, data),
  bgPage: (data) => get(`${url2}bg/gridpatrol/bgPage`, data),
  bgDataCheck: (data) => get(`${url2}wx/ssservicegovernance/bgData`, data),
  bgPageCheck: (data) => get(`${url2}wx/ssservicegovernance/bgPage`, data),
  getByCodeOther: (data) => get(`${url2}type/type-manage/getByGroup`, data),
  getDetails: (data) => get(`${url2}wx/ssservicegovernance/getDetails`, data),
  bgtree: (data) => get(`${url2}sys-simplex/simplex/bg/tree`, data),
  getTbData: (data) => get(`${url2}sys-simplex/simplex/bg/getData`, data),

  // ----
  getTypeInfo: (data) =>
    get(`${url2}landmaptype/landmaptype/treeBg?type=1`, data),

  simplexDetail: (data) => get(`${url2}sys-simplex/simplex/detail`, data),
  subSimplex: (data) =>
    post(`${url2}sys-simplex/simplex/submit`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getAllModelList: (data) => get(`${url2}cityModel/getAllModelList`, data),
  // treeBgOther: (data) => get(`${url2}landmaptype/landmaptype/treeBg?type=2`, data),
  treeBgOther: (data) => get(`${url2}cityModel/treeBg`, data),
  simplexRemove: (data) =>
    post(`${url2}sys-simplex/simplex/remove?ids=${data.ids}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  iscPlaybackURLs: (data) =>
    get(`${url2}bg/city/monitor/iscPlaybackURLs`, data),

  getDxfJson: (data) => get(data.url),

  flightAttendCount: (data) => get(`${url2}bg/flight/flightAttendCount`, data),

  detailByTbCode: (data) =>
    get(`${url2}sys-simplex/simplex/bg/detailByTbCode`, data),

  // 获取判断数组
  getDict: (data) => get(`${url2}landmaptype/landmaptype/getDict`, data),

  // -----无人机录像回放------

  // 登录
  uavLogin: (data) => get(`${uvaUrl}/api/v1/login`, data),
  // 登出
  uavLogout: (data) => get(`${uvaUrl}/api/v1/logout`, data),

  // 获取当前登录用户信息
  uavGetuserinfo: (data) => get(`${uvaUrl}/api/v1/getuserinfo`, data),
  // 开始回放
  uavPlaybackStart: (data) => get(`${uvaUrl}/api/v1/playback/start`, data),

  // 回放流停止
  uavPlaybackStop: (data) => get(`${uvaUrl}/api/v1/playback/stop`, data),
  // 查询录像通道

  querydevices: (data) => get(`${uvaUrl}/api/v1/record/querydevices`, data),
  // 获取通道状态记录

  getchannelstatuslog: (data) =>
    get(`${uvaUrl}/api/v1/getchannelstatuslog`, data),
  // 设置通道禁用启用
  setchannelenable: (data) => get(`${uvaUrl}/api/v1/setchannelenable`, data),
  // 查询录像列表
  recordlist: (data) => get(`${uvaUrl}/api/v1/playback/record/list`, data),
  // 按日查询通道录像

  querydaily: (data) => get(`${uvaUrl}/api/v1/record/querydaily`, data),
  // 获取通道信息
  // 统计通道所有录像记录
  getchannels: (data) => get(`${uvaUrl}/api/v1/getchannels`, data),

  queryflags: (data) => get(`${uvaUrl}/api/v1/record/queryflags`, data),

  getchannelstream: (data) => get(`${uvaUrl}/api/v1/getchannelstream`, data),

  filterJson: (data) =>
    get(
      `https://live.3dy.me/XPDT/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=XPDT:乡镇村&outputFormat=json`,
      data
    ),

  filterJson1: (data) => get(`${data.url}`, { filter: data.filter }),

  filterJson22: (data) =>
    get(
      `https://live.3dy.me/WYSDT/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=WYSDT:武夷山村界含乡镇名&outputFormat=json`,
      data
    ),
  // --------------------
  原始影像: (lat, lng) =>
    post(`${url2}exif/getInfo?latitude=${lat}&longitude=${lng}`, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getVrInfos: (data) => get(`${url2}hf/hsyzypdetail/getVrInfos`, data),
  getWayLineTimeList: (data) =>
    post(`${url2}exif/getWayLineTimeList`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  exifDetail: (data) => get(`${url2}/exif/detail`, data),
  getVrPicInfos: (data) => get(`${url2}exif/getVrPicInfos`, data),
  pageV2: (data) => get(`${url2}city_flight_task/flighttask/pageV2`, data),
  getFlightTaskStatus: (data) =>
    get(`${url2}city_flight_task/flighttask/getFlightTaskStatus`, data),
  getFlightTaskType: (data) =>
    get(`${url2}city_flight_task/flighttask/getFlightTaskType`, data),
  detailV2: (data) =>
    get(
      `${url2}city_flight_way_line_detail/flightwaylinedetail/detailV2`,
      data
    ),
  listByWayLineUUID: (data) =>
    get(
      `${url2}city_flight_way_line_action/flightwaylineaction/listByWayLineUUID`,
      data
    ),

  listAllPosition: (data) => get(`${url2}flightLog/listAllPosition`, data),
  getLogDetailList: (data) => get(`${url2}flightLog/getLogDetailList`, data),
  listImg: (data) => get(`${url2}city_flight_task/flighttask/listImg`, data),
  sikongKmzAnalysis: (data) =>
    post(`${url2}bg/flight/sikongKmzAnalysis`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  eventMenus: (data) => get(`${url2}/router/eventMenus`, data),

  // -------------------------------------------------------
  getBgData: (data) => get(`${url2}hf/hsyzypdetail/getBgData`, data),

  // cc-------------------------------------------------------------------------------

  另类图斑树: () => get(`${url2}/seamaptype/seamaptype/treeBg`),
  修改基础信息: (data) =>
    post(`${url2}/hf/hsyzypdetail/submit`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  图斑地图信息: (data) => get(`${url2}/hf/hsyzypdetail/getTbMapInfos`, data),
  查询图斑: (data) => get(`${url2}/hf/hsyzypdetail/page`, data),
  另类图斑数据: (tbCode) =>
    get(`${url2}/hf/hsyzypdetail/getBreedDetail?tbCode=${tbCode}`),
  原始影像: (lat, lng) =>
    post(`${url2}/exif/getInfo?latitude=${lat}&longitude=${lng}`),
  删除用户: (id) => post(`${url2}/hf/hsyzypdetail/remove?ids=${id}`),
  新编号: (data) => get(`${url2}/hf/hsyzypdetail/buildTbCode?position=${data}`),
  申请起飞: (data) => post(`${url2}/uavlaunchapplication/apply`, data),
  saveBatch: (data) =>
    post(url2 + `/hf/hsyzypdetail/saveBatch`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  historyPage: (data) => get(url2 + "/hf/hsyzypdetail/historyPage", data),
  saveBatchQtInfo: (data) =>
    post(url2 + `/hf/hsyzypdetail/saveBatchQtInfo`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  removeByTbCode: (data) =>
    get(`${url2}/hf/hsyzypdetail/removeByTbCode?tbCode=${data}`),
  appQtInfoPage: (data) => get(`${url2}/hf/hsyzypdetail/appQtInfoPage`, data),
  removeHistory: (data) =>
    get(`${url2}/hf/hsyzypdetail/removeHistory?id=${data}`),

  // -------松城街道接口-----------
  getDpCount: () => get(`${url2}/transform/getDpCount`),
  getDpPage: (data) => get(`${url2}/transform/getDpPage`, data),
  getDictionary: (data) =>
    get(`${url2}/blade-system/dict-biz/dictionary`, data),
  getDpIntroductionById: (data) =>
    get(`${url2}/transform/getDpIntroductionById`, data),
  getDpLeaderById: (data) => get(`${url2}/transform/getDpLeaderById`, data),
  getDUnitById: (data) => get(`${url2}/transform/getDUnitById`, data),
  projectjournalPage: (data) => get(`${url2}/jcgz/projectjournal/bgPage`, data),
  getProjectJournalCount: (data) =>
    get(`${url2}/jcgz/projectjournal/getProjectJournalCount`, data),
  dateType: (data) => get(`${url2}/jcgz/projectjournal/dateType`, data),
  getBgProjectPlan: (data) =>
    get(`${url2}/jcgz/prolectandplan/getBgProjectPlan`, data),
  getBgProjectVr: (data) => get(`${url2}/jcgz/projectvr/getBgProjectVr`, data),
  getYSYX: (data) => post(`${apiUrl}/exif/getInfo`, data),
  getYSYX: (data) => post(`${url2}/exif/getInfo`, data),
  getInfoByMaxMinLocaltion: (data) =>
    post(`${url2}/exif/getInfoByMaxMinLocaltion`, data),

  // -----------新无人机接口---------------------------------------------
  getTaskAndLogs: (data) =>
    get(`${url2}/city_flight_task/flighttask/getTaskAndLogs`, data),
  flightSimpleList: (data) =>
    get(`${url2}/city_flight_task/flighttask/flightSimpleList`, data),
  sikongKmzAnalysisZf: (data) =>
    get(`${url2}/city_flight_task/flighttask/sikongKmzAnalysis`, data),
  getFlightWaylineType: (data) =>
    get(
      `${url2}/city_flight_way_line_detail/flightwaylinedetail/getFlightWaylineType`,
      data
    ),
  getVrPicInfosV2: (data) => get(`${url2}exif/getVrPicInfosV2`, data),
  // -----------------扫码登录--------------------------------
  // getCodeEWM: (data) => get(url2 + "/blade-auth/build/code", data),
  getCodeEWM: (data) => get(url2 + "/blade-auth/build/code", data),

  // ---------------摄像头接口------------------------------------------
  monitorDetails: (data) => get(`${url2}bg/city/monitor/monitorDetails`, data),
  // -------标题名称切换---------------------
  getUserRole: (data) => get(`${url2}blade-system/role/getUserRole`, data),
  // ---原始影像列表
  listImg原始影像: (data) => get(`${url2}transform/listImg`, data),
  getVrList: (data) => get(`${url2}transform/getVrList`, data),
  getDefault: (data) => get(`${url2}defaultborder/getDefault`, data),

  // 标注接口------------------------------
  imageAddEvent: (data) =>
    post(`${url2}/flightcustomizeevent/addEvent`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  getImageEvent: (data) =>
    get(`${url2}/flightcustomizeevent/listByExifId`, data),

  // 事件预警
  bgEventList: (data) => get(`${url2}/flightcustomizeevent/bgEventList`, data),
  getMapPoint: (data) => get(`${url2}//flightcustomizeevent/getMapPoint`, data),
  bgEventStatusList: (data) =>
    get(`${url2}/flightcustomizeevent/bgEventStatusList`, data),
  bgEventSourceList: (data) =>
    get(`${url2}/flightcustomizeevent/bgEventSourceList`, data),
  getPushPeople: (data) => get(`${url2}/bg/gridpatrol/getPushPeople`, data),

  bgEventEnum: (data) => get(`${url2}/flightcustomizeevent/bgEventEnum`, data),

  bgEventDetail: (data) =>
    get(`${url2}/flightcustomizeevent/bgEventDetail`, data),

  getDetails: (data) => get(`${url2}/wx/ssservicegovernance/getDetails`, data),

  save: (data) =>
    post(`${url2}/wx/ssreportprocessing/save`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  bgEventCount: (data) =>
    get(`${url2}/flightcustomizeevent/bgEventCount`, data),

  // -------------------人车识别新接口-----------------------
  //获取人车识别的图表数据
  getChartNew: (data) => get(`${url2}/bg/city/RCsb/getChart`, data),
  //获取通行记录详情
  getRCsbDetail: (data) => get(`${url2}/bg/city/RCsb/getRCsbDetail`, data),
  //获取人车识别列表
  getRCsbList: (data) => get(`${url2}/bg/city/RCsb/getRCsbList`, data),

  getRCsbListOther: (data) =>
    get(
      (data.url ? data.url : globalData.IP) + "/api/bg/city/ISC/getRCsbList",
      data
    ),

  //获取摄像头统计信息
  getRCsbMonitorCount: (data) =>
    get(`${url2}/bg/city/RCsb/getRCsbMonitorCount`, data),
  getRCDict: (data) => get(`${url2}/bg/city/RCsb/getRCDict`, data),

  // ------------标注接口------------------
  tagEnum: (data) => get(`${url2}/cityFlightSkTag/tagEnum`, data),
  statistics: (data) => get(`${url2}/cityFlightSkTag/statistics`, data),
  groupTree: (data) => get(`${url2}/cityFlightSkTag/groupTree`, data),
  pullFromSk: (data) => get(`${url2}/cityFlightSkTag/pullFromSk`, data),
  cityFlightSkTagTree: (data) => get(`${url2}/cityFlightSkTag/tree`, data),
  cityFlightSkTagMove: (data) =>
    post(`${url2}/cityFlightSkTag/move`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  cityFlightSkTagUpdate: (data) =>
    post(`${url2}cityFlightSkTag/update`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  cityFlightSkTagRemove: (data) =>
    post(`${url2}cityFlightSkTag/remove?id=${data}`),
  propertyList: (data) => get(`${url2}type-property/list`, data),

  cityFlightSkTagSave: (data) =>
    post(`${url2}/cityFlightSkTag/save`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  cityFlightSkTagLock: (data) =>
    post(`${url2}/cityFlightSkTag/lock?ids=${data.ids}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  cityFlightSkTagUnlock: (data) =>
    post(`${url2}/cityFlightSkTag/unlock?ids=${[data.ids]}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  cityFlightSkTagDetail: (data) => get(`${url2}/cityFlightSkTag/detail`, data),
  flightcustomizeeventEventDetail: (data) =>
    get(`${url2}/flightcustomizeevent/eventDetail`, data),
  showOnMap: (data) =>
    post(`${url2}/cityFlightSkTag/showOnMap`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),
  updateName: (data) =>
    post(`${url2}/cityFlightSkTag/updateName`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  hsyzdetailSubmit: (data) =>
    post(`${url2}/hf/hsyzdetail/submit`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  simpleElementList: (data) =>
    get(`${url2}/cityFlightSkTag/simpleElementList`, data),

  iscBatchPlayAddressNew: (data) =>
    post(
      (data.url ? data.url : globalData.IP) +
        `/api/bg/city/ISC/iscBatchPlayAddress`,
      data,
      {
        "content-type": "application/json;charset=UTF-8",
      }
    ),

  submitPersonBlack: (data) =>
    post(
      (data.url ? data.url : globalData.IP) +
        `/api/bg/city/RCsb/submitPersonBlack`,
      data,
      {
        "content-type": "application/json;charset=UTF-8",
      }
    ),
  addFollow: (data) =>
    post(`${url2}/city/blacklist/addFollow`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  // ---------------渔排新增  编辑   删除接口-----------------------
  hsyzdetailRemove: (data) =>
    post(`${url2}/hf/hsyzdetail/remove?ids=${data}`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  hsyzdetailSubmit: (data) =>
    post(`${url2}/hf/hsyzdetail/submit`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  updateYpAndPeople: (data) =>
    post(`${url2}/hf/hsyzdetail/updateYpAndPeople`, data, {
      "content-type": "application/json;charset=UTF-8",
    }),

  lazyTree: (data) => get(`${url2}/blade-system/region/lazy-tree`, data),
  childList: (data) => get(`${url2}/blade-desk/grid/child-list`, data),

  userPage: (data) => get(`${url2}/user/page`, data),
  userDetail: (data) => get(`${url2}/user/detail`, data),
};
