<template>
  <div class="headers">
    <!-- <img class="backImg" src="@/assets/img/A01.png" alt=""> -->
    <div class="header-info" v-if="show">
      <span class="header-info-datetime-time" v-if="hour != ''">
        {{ hour }}:{{ minute }}:{{ second }}
      </span>
      <div class="bottomInfo">
        <div class="header-info-weather">
          <img class="header-info-weather-icon" v-if="icon" :src="icon" />
          <div>
            <p class="header-info-weather-temperature">{{ temperature }}</p>
          </div>
        </div>
        <div class="header-info-datetime">
          <span class="header-info-datetime-date" v-if="year != ''">
            <span class="header-info-datetime-date-direction">{{
                direction
            }}</span>
            <!-- <span>{{ year }}/{{ month }}/{{ date }}</span> -->
            <span>{{ month }}月{{ date }}日</span>
            <span class="header-info-datetime-date-week">{{ day }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeather } from "@/api";
export default {
  props: ["secMenu"],
  name: "Headers",
  computed: {},
  data() {
    return {
      // 时间天气
      year: "",
      month: "",
      date: "",
      day: "",
      hour: "",
      minute: "",
      second: "",
      city: "",
      direction: "",
      temperature: "",
      icon: "",
      header: false,
      show: false,
    };
  },
  computed: {


  },
  created() {

  },
  activated() {
    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  deactivated() {
    this.show = false;
  },
  mounted() {
    this.time();
    setInterval(this.time, 1000);
    // getWeather({ city: "南平市" }).then((res) => {
    //   // console.log(res);
    //   this.city = "南平市"; //所在城市
    //   var maxTemperature = res.data.forecast[0].high; //最高温度
    //   var minTemperature = res.data.forecast[0].low; //最低温度
    //   var weather =
    //     minTemperature.split(" ")[1].split("℃")[0] +
    //     "~" +
    //     maxTemperature.split(" ")[1].split("℃")[0] +
    //     "°C";
    //   this.temperature = weather;
    //   var type = res.data.forecast[0].type; //天气状态
    //   var fengxiang = res.data.forecast[0].fengxiang; //天气状态
    //   this.direction = fengxiang + " " + type;
    //   this.icon = require(`@/assets/weather/${type}.png`);
    // });
  },
  methods: {
    // 时间 日期
    time() {
      var date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.date = date.getDate();
      this.day = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      )[date.getDay()];
      this.hour =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      this.minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      this.second =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

        
      // if (date.getHours() >= 6 && date.getHours() < 18) {
      //   if (this.$store.state.index.DayNight == '白天') return
      //   this.$store.commit("index/set", {
      //     key: "DayNight",
      //     val: '白天',
      //   })
      // } else {
      //   if (this.$store.state.index.DayNight == '黑夜') return
      //   this.$store.commit("index/set", {
      //     key: "DayNight",
      //     val: '黑夜',
      //   })
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
// $colord: rgba(10, 189, 164, 1);
$colord: #FFFFC7;

.headers {
  position: relative;
  z-index: 12;
  pointer-events: none;
  width: 100%;

  .header-info {
    position: fixed;
    z-index: 99;
    right: 255px;
    top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .header-info-datetime-time {
      line-height: 30px;
      margin-left: 20px;
      letter-spacing: 2px;
      font-size: 30px;
      font-family: Square722;
      font-weight: bold;
      color: $colord;
    }

    .bottomInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;

      .header-info-weather {
        text-align: left;
        display: flex;
        align-items: center;

        .header-info-weather-temperature {
          display: block;
          font-size: 20px;
          line-height: 20px;
          font-family: Square721;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
        }

        .header-info-weather-direction {
          display: block;
          font-size: 16px;
          line-height: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          margin-top: 5px;
        }

        .header-info-weather-icon {
          height: 30px;
          margin-right: 10px;
        }
      }

      .header-info-datetime {
        text-align: center;
        display: flex;
        align-items: center;

        .header-info-datetime-date {
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: $colord;
          margin-left: 10px;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          margin-top: 3px;

          .header-info-datetime-date-week {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: $colord;
            white-space: nowrap;
            margin-left: 10px;
            letter-spacing: 1px;
          }

          .header-info-datetime-date-direction {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: $colord;
            margin-right: 24px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

.backImg {
  position: fixed;
  left: 30px;
  top: 23px;
}
</style>
