import { reactive, nextTick, watch, ref } from "vue";
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export const globalData = reactive({
  userList:[],
  allUserList:false,
  // 公共霞浦网格数据
  xiaPuGrid: [],
  sum: 0, // 网格面积
  gridChange: false,
  addYsd: false,
  earthArr: {
    天地图影像: {
      name: "天地图影像",
      zIndex: 1,
      icon: "img/basemaps/tdt_img.png",
      type: "tdt",
      layer: "img_d",
      show: true,
    },
    谷歌影像: {
      hasZIndex: true,
      zIndex: 1,
      name: "谷歌影像",
      type: "xyz",
      url: "https://npmap.3dy.me/map/xpmap/{z}/{x}/{y}.jpg",
      chinaCRS: mars3d.ChinaCRS.GCJ02,
      show: true,
    },
    ArcGIS影像: {
      hasZIndex: true,
      zIndex: 1,
      name: "ArcGIS影像",
      icon: "/img/basemaps/esriWorldImagery.png",
      type: "xyz",
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      enablePickFeatures: false,
      show: true,
    },
  },
  xiapulevel: {
    "/CockpitFir": 1,
    "/PartyGridSummary": 1,
    "/PartyMap": 1,
    "/PartyReport": 1,
    "/RedAlert": 1,
    "/RealPeople": 1,
    "/RealHouse": 1,
    "/ImportantObj": 1,
    "/ImportantMan": 1,
    "/ImportantPart": 1,
    "/ImportantPlace": 1,
    "/VideoReal": 1,
    "/EnterpriseGrid": 1,
    "/ProvideAged": 1,
    "/CureAndBring": 1,
    "/VideoLinkpage": 1,
    "/LogBook": 1,
    "/GridGovern": 1,
    "/CrowdCalls": 1,
    "/DrugControl": 1,
  },
  // -----热力图用的数据-------------

  heatObj: {
    "/RealPeople": "value",
    "/RealHouse": "houseValue",
    "/ImportantObj": "keyServiceValue",
    "/EnterpriseGrid": "companyValue",
    "/ImportantMan": "keyPersonValue",
    "/ReportProcessing": "ReportProcessingValue",
  },
  heatData: "",
  heatShow: false,
  // -----------------------------------
  modelOption: {
    Memory: 216,
    // 额定显存大小(以字节为单位)，允许在这个值上下波动
    cacheBytes: 536870912,
    // 最大显存大小(以字节为单位)。
    maximumCacheOverflowBytes: 536870912,
    // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
    maximumScreenSpaceError: 16,
    // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
    cullRequestsWhileMovingMultiplier: 60,
    // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
    preloadFlightDestinations: true,
    // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
    skipLevelOfDetail: false,
  },
  menuArr: [],
  rightStatus: false,
  socketData: {},
  allNavShow: true, // 控制总菜单 是否显示
  secUrl: "",
  chooseBtns: {
    时间筛选: false,
    事件类型: false,
    事件状态: false,
    所属网格: false,
    上报站点: false,
    网格巡查事件状态: false,
    网格巡查事件类型: false,
    网格巡查事件来源: false,
    巡查状态: false,
    工程车事件类型: false,
    智能预警事件类型: false,
    巡查状态: false,
    巡查状态: false,
    巡查状态: false,
    巡查状态: false,
    小时筛选: false,
    景点监控: false,
    码头监控: false,
  },

  clickType: "",
  wmsObj: {
    name: false,
    value: false,
  },
  singleObj: {
    name: false,
    value: false,
    idx: false,
  },
  tbArr: [], // 三调图斑  二调图斑   三区三线 显示的数组
  tbTypes: [], // 三调图斑  二调图斑   三区三线 3中类型
  ftbArr: {},
  carOption: false, // 工程车 筛选条件
  fromList: false, // 工程车管控判断是  地图上详情按钮点击false  还是右侧详情列表点击true
  rcsbData: false,
  // rcsbData: {
  //   age: "",
  //   azimuth: "",
  //   brand: "",
  //   carPicUri: "",
  //   clothingColor: "",
  //   clothingType: "",
  //   createTime: "2025-01-21 11:39:53",
  //   deviceNumber: "",
  //   hasBag: "",
  //   hasCarrying: "",
  //   hasHat: "",
  //   id: "1881547233418653697",
  //   licensePlate: "",
  //   monitorId: "195222226845676",
  //   monitorName: "下仕塘中段(麒麟山)",
  //   name: "ces7",
  //   pantsColor: "",
  //   pantsType: "",
  //   personAge: "未知",
  //   personGender: "女",
  //   personGlass: "未知",
  //   personId: "1881547231069843458",
  //   phone: "",
  //   photoUrl: "https://xpwghoss.3dy.me/FsJNMv0rueLX57loEUKA5q1JSP4i",
  //   plateType: "",
  //   position: '{"alt":22,"lat":26.54040634314437,"lng":119.838000528776}',
  //   rcType: 1,
  //   sex: "",
  //   snapUrl: "https://xpwghoss.3dy.me/FuuNxQPOmj7tf55mnMnhsITlzJj2",
  //   speed: "",
  //   status: "",
  //   typeId: "35928203692",
  //   typeName: "重点人员",
  //   views:
  //     '{"lat":26.702746,"lng":119.896303,"alt":808,"heading":0,"pitch":-90}',
  // },
  all_Status_hjf: [
    "",
    "未确定",
    "",
    "有效预警",
    "",
    "无效预警",
    "已转报",
    "处理中",
    "已完成",
  ],
  logoTitle: "",
  bzData: false,
  bzShow: false,
  vehicleTypes: [],
  points: [],
  plateTypes: [],
  IP: "",
  manageShowInMap: true,
  showManageList: false,
  projectId: "", // 项目id
  routeData: [],
  routeId:"",
  updateYpList:'',
});
